import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, publicRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import ThemeSettings from "../InitialPage/themeSettings";
import PublicRoute from "./publicRoute";
import PrivateRoute from "./privateRoute";
import { USER_DETAILS } from "../utils/encryptedConstants.";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      {/* <Loader /> */}
      <Header />
      <Sidebar />
      <Outlet />
      <ThemeSettings />
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      {/* <Loader /> */}
      <ThemeSettings />
    </div>
  );

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            sessionStorage.getItem(USER_DETAILS) ? (
              <Navigate to="/admin-dashboard" />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />
        {/* Public routes (accessible without authentication) */}
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Authpages />}>
            {pagesRoute.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
        </Route>

        {/* Private routes (accessible only with authentication) */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<HeaderLayout />}>
            {publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
        </Route>
        {/* <Route
          path="/"
          element={
            sessionStorage.getItem(USER_DETAILS) ? (
              <Navigate to="/admin-dashboard" />
            ) : (
              <Navigate to="/signin" />
            )
          }
        /> */}
      </Routes>

      {/* <Routes>
        <Route path={"/"} element={<HeaderLayout />}>
          {publicRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>

        <Route path={"/"} element={<Authpages />}>
          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
      </Routes> */}
    </div>
  );
};
export default AllRoutes;
