import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CREATE_PRODUCTS } from "../apollo/mutations";
import { SESSION_STORAGE } from "../common/masterEnums";
import { decryptData } from "../utils/encryptData";
import { GENERAL } from "../utils/encryptedConstants.";
import {
  getSelectedStoreDetails,
  payloadValidator,
} from "../utils/helperFunctions";

const defaultValues = {
  sku: null,
  name: null,
  description: null,
  category_id: null,
  subCategory_id: null,
  brand_id: null,
  images: null,
  price: null,
  off_type: null,
  unit_id: null,
  discount_value: null,
  stock: [],
};

const useCreateProduct = () => {

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues });

  const navigate = useNavigate()
  let general = decryptData(GENERAL, SESSION_STORAGE)

  const category_id = watch("category_id");

  const [submit] = useMutation(CREATE_PRODUCTS);

  const onAddProduct = async (data) => {
    try {
      let category_id =
        data?.subCategory_id == null
          ? data?.category_id
          : data?.subCategory_id;

      delete data.warehouse_id;
      delete data?.subCategory_id;
      
      let payload = {
        ...data,
        category_id: category_id,
        store_id: getSelectedStoreDetails("id"),
        sku: data?.sku?.toString(),
        price: parseFloat(data?.price),
        discount_value: parseFloat(data?.discount_value),
        unit_id: parseInt(data?.unit_id),
        stock: data?.stock.map((item) => ({
          ...item,
          quantity: parseInt(item?.quantity, 10),
        })),
      };
      console.log("data", data)
      console.log("payload", payload)
      await submit(payloadValidator(payload));
      navigate("/product-list")
      reset(defaultValues);
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return {
    values: {
      control,
      errors,
      image: watch("image"),
      setValue,
      category_id,
      discount_types: general?.off_type?.map(item => ({ label: item, value: item }))
    },
    getValues,
    functions: {
      onSubmit: handleSubmit(onAddProduct),
      onCancel,
    },
  };
};

export default useCreateProduct;
