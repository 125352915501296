import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../Router/all_routes";
// import { DatePicker } from "antd";
import {
  ArrowLeft,
  // Calendar,
  ChevronDown,
  ChevronUp,
  Info,
  LifeBuoy,
  // List,
  PlusCircle,
  Trash2,
  X,
} from "feather-icons-react/build/IconComponents";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  LIST_BRANDS,
  LIST_CATEGORY,
  LIST_UNITS,
  LIST_WAREHOUSE,
} from "../../apollo/queries";
import useCreateProduct from "../../controllers/useCreateProduct";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import AddBrand from "../../core/modals/addbrand";
import AddCategory from "../../core/modals/inventory/addcategory";
import Addunits from "../../core/modals/inventory/addunits";
import { setToogleHeader } from "../../core/redux/action";
import ErrorMessage from "../../feature-module/components/errorMessage";
import InputField from "../../feature-module/components/InputField";
import { getSelectedStoreDetails } from "../../utils/helperFunctions";
import Dropdown from "../components/Dropdown";

const AddProduct = () => {
  const { values, functions, getValues } = useCreateProduct();
  const route = all_routes;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const [generatedSKU, setGeneratedSKU] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [isSubCategoryRequired, setIsSubCategoryRequired] = useState(false);
  const [subCategories, setSubCategories] = useState([]);

  const generateRandomSKU = () => {
    const randomSKU = Math.floor(Math.random() * 1000000); // Generates a 6-digit random number
    setGeneratedSKU(randomSKU);
    values.setValue("sku", randomSKU);
  };

  const handleChangeWarehouse = (selectedOption) => {
    setSelectedWarehouse(selectedOption);
    const existingStock = getValues("stock") || [];
    const filteredTwo = existingStock.filter((itemTwo) =>
      selectedOption.some((itemOne) => itemOne.value === itemTwo.warehouse_id)
    );
    values.setValue("stock", filteredTwo);
  };

  const handleQuantityChange = (index, e) => {
    const existingStock = getValues("stock") || [];
    const existingWarehouse = getValues("warehouse_id") || [];
  
    console.log("existingWarehouse:", existingWarehouse); 
    if (existingStock[index]) {
      existingStock[index] = {
        quantity: e.target.value,
        warehouse_id: existingWarehouse[index] || "",
      };
      console.log("existingStock", existingStock)
      values.setValue("stock", existingStock);
    }
  };

  // const handleQuantityChange = (index, e) => {
  //   const existingStock = getValues("stock") || [];
  //   const existingWarehouse = getValues("warehouse_id") || [];
  //   if (existingStock[index]) {
  //     existingStock[index] = {
  //       quantity: e,
  //       warehouse_id: existingWarehouse[index],
  //     };
  //     values.setValue("stock", existingStock);
  //   }
  // };

  const handleChangeCategory = (data, selectedOption) => {
    values.setValue("category_id", selectedOption?.value);
    const filteredCategories = data?.listCategories?.filter(
      (category) => category?.parent_id === selectedOption.value
    );
    console.log("filteredCategories", filteredCategories);
    if (filteredCategories?.length > 0) {
      let dataMaking = filteredCategories?.map((x) => ({
        label: x.name,
        value: x.id,
      }));
      setSubCategories(dataMaking);
      setIsSubCategoryRequired(true);
    } else {
      setSubCategories([]);
      setIsSubCategoryRequired(false);
    }
  };

  // const [selectedDate, setSelectedDate] = useState(new Date());
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  // const [selectedDate1, setSelectedDate1] = useState(new Date());
  // const handleDateChange1 = (date) => {
  //   setSelectedDate1(date);
  // };
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  // const store = [
  //   { value: "choose", label: "Choose" },
  //   { value: "thomas", label: "Thomas" },
  //   { value: "rasmussen", label: "Rasmussen" },
  //   { value: "fredJohn", label: "Fred John" },
  // ];
  // const warehouse = [
  //   { value: "choose", label: "Choose" },
  //   { value: "legendary", label: "Legendary" },
  //   { value: "determined", label: "Determined" },
  //   { value: "sincere", label: "Sincere" },
  // ];
  // const category = [
  //   { value: "choose", label: "Choose" },
  //   { value: "lenovo", label: "Lenovo" },
  //   { value: "electronics", label: "Electronics" },
  // ];
  // const subcategory = [
  //   { value: "choose", label: "Choose" },
  //   { value: "lenovo", label: "Lenovo" },
  //   { value: "electronics", label: "Electronics" },
  // ];
  // const subsubcategories = [
  //   { value: "Fruits", label: "Fruits" },
  //   { value: "Computer", label: "Computer" },
  //   { value: "Shoes", label: "Shoes" },
  // ];
  // const brand = [
  //   { value: "choose", label: "Choose" },
  //   { value: "nike", label: "Nike" },
  //   { value: "bolt", label: "Bolt" },
  // ];
  // const unit = [
  //   { value: "choose", label: "Choose" },
  //   { value: "kg", label: "Kg" },
  //   { value: "pc", label: "Pc" },
  // ];
  // const sellingtype = [
  //   { value: "choose", label: "Choose" },
  //   { value: "transactionalSelling", label: "Transactional selling" },
  //   { value: "solutionSelling", label: "Solution selling" },
  // ];
  // const barcodesymbol = [
  //   { value: "choose", label: "Choose" },
  //   { value: "code34", label: "Code34" },
  //   { value: "code35", label: "Code35" },
  //   { value: "code36", label: "Code36" },
  // ];
  // const taxtype = [
  //   { value: "exclusive", label: "Exclusive" },
  //   { value: "salesTax", label: "Sales Tax" },
  // ];
  // const discounttype = [
  //   { value: "choose", label: "Choose" },
  //   { value: "percentage", label: "Percentage" },
  //   { value: "cash", label: "Cash" },
  // ];
  // const discounttype1 = [
  //   { value: "choose", label: "Choose" },
  //   { value: "percentage", label: "Percentage" },
  //   { value: "cash", label: "Cash" },
  // ];
  // const [isImageVisible, setIsImageVisible] = useState(true);

  // const handleRemoveProduct = () => {
  //   setIsImageVisible(false);
  // };
  // const [isImageVisible1, setIsImageVisible1] = useState(true);

  // const handleRemoveProduct1 = () => {
  //   setIsImageVisible1(false);
  // };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>New Product</h4>
              <h6>Create new product</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.productlist} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />
                  Back to Product
                </Link>
              </div>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Collapse"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={() => {
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp className="feather-chevron-up" />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Product Information</span>
                        </h5>
                        <Link to="#">
                          <ChevronDown className="chevron-down-add" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Store</label>
                            <input
                              type="text"
                              className="form-control"
                              value={getSelectedStoreDetails("name")}
                              disabled
                            />
                            {/* <Controller
                              name="stock.store_id"
                              control={values.control}
                              rules={{ required: "Store is required" }}
                              render={({ field }) => (
                                <Dropdown
                                  query={LIST_STORE}
                                  dataKey="listStore"
                                  variables={{
                                    ownerId: null,
                                  }}
                                  label="Store"
                                  placeholder="Select a Store"
                                  field={field}
                                  onChange={handleStoreChange}
                                />
                              )}
                            />
                            <ErrorMessage
                              error={values.errors?.stock?.store_id?.message}
                            /> */}
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <Controller
                              name="warehouse_id"
                              control={values.control}
                              rules={{ required: "Warehouse is required" }}
                              render={({ field }) => (
                                <Dropdown
                                  query={LIST_WAREHOUSE}
                                  dataKey="listWarehouse"
                                  variables={{
                                    storeId: getSelectedStoreDetails("id"),
                                  }}
                                  isMulti={true}
                                  label="Warehouse"
                                  required
                                  placeholder="Select a Warehouse"
                                  field={field}
                                  onChange={handleChangeWarehouse}
                                />
                              )}
                            />
                            <ErrorMessage
                              error={
                                values.errors?.stock?.warehouse_id?.message
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <Controller
                              name="sku"
                              control={values.control}
                              rules={{ required: "SKU is required" }}
                              render={({ field }) => (
                                <InputField
                                  required={true}
                                  label="SKU"
                                  type="text"
                                  placeholder="Enter SKU"
                                  value={generatedSKU || values.control}
                                  {...field}
                                />
                              )}
                            />
                            <ErrorMessage error={values.errors?.sku?.message} />
                            <Link
                              className="btn btn-primaryadd"
                              onClick={generateRandomSKU}
                            >
                              Generate Code
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12">
                          <div className="mb-3 add-product">
                            <Controller
                              name="name"
                              control={values.control}
                              rules={{
                                required: "Product Name is required",
                                minLength: {
                                  value: 3,
                                  message: "Minimum 3 characters",
                                },
                              }}
                              render={({ field }) => (
                                <InputField
                                  label="Product Name"
                                  type="text"
                                  placeholder="Enter Product Name"
                                  required={true}
                                  value={values?.control}
                                  {...field}
                                />
                              )}
                            />
                            <ErrorMessage
                              error={values.errors?.name?.message}
                            />
                            {/* <label className="form-label">Product Name</label>
                            <input type="text" className="form-control" /> */}
                          </div>
                        </div>
                        {/* <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Slug</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div> */}
                      </div>
                      <div className="addservice-info">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <Controller
                                name="brand_id"
                                control={values.control}
                                rules={{ required: "Brand is required" }}
                                render={({ field }) => (
                                  <Dropdown
                                    query={LIST_BRANDS}
                                    variables={{
                                      storeId: getSelectedStoreDetails("id"),
                                    }}
                                    required
                                    dataKey="listBrand"
                                    label="Brand"
                                    placeholder="Select a Brand"
                                    field={field}
                                  />
                                )}
                              />
                              <ErrorMessage
                                error={values.errors?.brand_id?.message}
                              />
                              {/* <div className="add-newplus">
                                <label className="form-label">Brand</label>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add-units-brand"
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Add New</span>
                                </Link>
                              </div>
                              <Select
                                classNamePrefix="react-select"
                                options={brand}
                                placeholder="Choose"
                              /> */}
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <Controller
                                name="category_id"
                                control={values.control}
                                rules={{ required: "Category is required" }}
                                render={({ field }) => (
                                  <Dropdown
                                    query={LIST_CATEGORY}
                                    variables={{
                                      storeId: getSelectedStoreDetails("id"),
                                    }}
                                    required
                                    dataKey="listCategories"
                                    label="Category"
                                    placeholder="Select a Category"
                                    field={field}
                                    onChange={handleChangeCategory}
                                  />
                                )}
                              />
                              <ErrorMessage
                                error={values.errors?.category_id?.message}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Sub Category</label>
                              <Controller
                                name="subCategory_id"
                                control={values.control}
                                rules={
                                  isSubCategoryRequired
                                    ? { required: "Sub Category is required" }
                                    : {}
                                }
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    classNamePrefix="react-select"
                                    className="mb-2"
                                    options={subCategories}
                                    isDisabled={!isSubCategoryRequired}
                                    placeholder="Select Sub Category"
                                    value={subCategories.find(
                                      (option) => option.value === field.value
                                    )}
                                    onChange={(selectedOption) =>
                                      field.onChange(selectedOption.value)
                                    }
                                  />
                                )}
                              />
                              <ErrorMessage
                                error={values?.errors?.subCategory_id?.message} 
                              />
                            </div>
                          </div>

                          {/* <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <Controller
                                name="category_id"
                                control={values.control}
                                rules={
                                  isSubCategoryRequired
                                    ? { required: "Sub Category is required" }
                                    : {}
                                }
                                render={({ field }) => (
                                  <Dropdown
                                    query={GET_CATEGORY_BY_ID}
                                    variables={{
                                      getCategoryId: values?.category_id,
                                    }}
                                    dataKey="getCategory"
                                    label="Sub Category"
                                    placeholder="Select a Sub Category"
                                    field={field}
                                    disabled={!isSubCategoryEnabled}
                                  />
                                )}
                              />
                              <ErrorMessage
                                error={values.errors?.category_id?.message}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="add-product-new">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label">Unit</label>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add-unit"
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Add New</span>
                                </Link>
                              </div>
                              <Select
                                classNamePrefix="react-select"
                                options={unit}
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Selling Type</label>
                              <Select
                                classNamePrefix="react-select"
                                options={sellingtype}
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Barcode Symbology
                            </label>
                            <Select
                              classNamePrefix="react-select"
                              options={barcodesymbol}
                              placeholder="Choose"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>Item Code</label>
                            <input
                              type="text"
                              className="form-control list"
                              placeholder="Please Enter Item Code"
                            />
                            <Link
                              to={route.addproduct}
                              className="btn btn-primaryadd"
                            >
                              Generate Code
                            </Link>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product">
                            <Controller
                              name="stock.quantity"
                              control={values.control}
                              rules={{ required: "Quantity is required" }}
                              render={({ field }) => (
                                <InputField
                                  label="Quantity"
                                  type="text"
                                  value={values?.control}
                                  {...field}
                                />
                              )}
                            />
                            <ErrorMessage
                              error={values.errors?.stock?.quantity?.message}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product">
                           
                            <label>Price</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div> */}
                      {/* Editor */}
                      <div className="col-lg-12">
                        <div className="input-blocks summer-description-box transfer mb-3">
                          <Controller
                            name="description"
                            control={values.control}
                            rules={{
                              required: "Description is required",
                              maxLength: {
                                value: 500,
                                message: "Maximum 500 characters allowed",
                              },
                            }}
                            render={({ field }) => (
                              <InputField
                                label="Description"
                                placeholder="Enter Product Description Here"
                                type="textarea"
                                required={true}
                                helper_text={"Maximum 500 Characters"}
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage
                            error={values.errors?.description?.message}
                          />
                        </div>
                      </div>
                      {/* /Editor */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  {/* <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Quantity</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Price</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Tax Type</label>
                                <Select
                                  classNamePrefix="react-select"
                                  options={taxtype}
                                  placeholder="Select Option"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Discount Type</label>
                                <Select
                                  classNamePrefix="react-select"
                                  options={discounttype}
                                  placeholder="Choose"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Discount Value</label>
                                <input type="text" placeholder="Choose" />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Quantity Alert</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div> */}
                  {/* <div
                    className="accordion-card-one accordion"
                    id="accordionExample3"
                  >
                    <div className="accordion-item">
                      <div className="accordion-header" id="headingThree">
                        <div
                          className="accordion-button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-controls="collapseThree"
                        >
                          <div className="addproduct-icon list">
                            <h5>
                              <h5>
                                <Info className="add-info" />

                                <span>Images</span>
                              </h5>
                            </h5>
                            <Link to="#">
                              <ChevronDown className="chevron-down-add" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample3"
                      >
                        <div className="accordion-body">
                          <div className="text-editor add-list add">
                            <div className="col-lg-12">
                              <Controller
                                name="images"
                                control={values?.control}
                                rules={{
                                  required: "At least one image is required",
                                  validate: (files) =>
                                    files && files.length > 0
                                      ? true
                                      : "You must add an image",
                                }}
                                render={({ field }) => (
                                  <>
                                    <div className="add-choosen">
                                      <div className="input-blocks">
                                        <div className="image-upload">
                                          <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                              const newFiles = Array.from(
                                                e.target.files
                                              );
                                              const updatedFiles = [
                                                ...(field.value || []),
                                                ...newFiles,
                                              ];
                                              field.onChange(updatedFiles);
                                            }}
                                            multiple
                                          />
                                          <div className="image-uploads">
                                            <PlusCircle className="plus-down-add me-0" />
                                            <h4>Add Images</h4>
                                          </div>
                                        </div>
                                      </div>

                                      {field.value &&
                                        field.value.length > 0 && (
                                          <div
                                            className="image-preview"
                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              gap: "10px",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            {field.value.map((file, index) => (
                                              <div
                                                key={index}
                                                className="phone-img"
                                                style={{
                                                  display: "inline-flex",
                                                  width: "130px",
                                                  height: "125px",
                                                  flexShrink: 0,
                                                }}
                                              >
                                                <ImageWithBasePath
                                                  src={file}
                                                  alt={`Uploaded Image ${
                                                    index + 1
                                                  }`}
                                                />
                                                <Link to="#">
                                                  <X
                                                    className="x-square-add remove-product"
                                                    onClick={() => {
                                                      const updatedFiles =
                                                        field.value.filter(
                                                          (_, i) => i !== index
                                                        );
                                                      field.onChange(
                                                        updatedFiles
                                                      );
                                                    }}
                                                  />
                                                </Link>
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                    </div>
                                    {values?.errors?.images && (
                                      <ErrorMessage
                                        error={values.errors?.images?.message}
                                      />
                                    )}
                                  </>
                                )}
                              />
                              <div className="add-choosen">
                                <div className="input-blocks">
                                  <div className="image-upload">
                                    <input type="file" />
                                    <div className="image-uploads">
                                      <PlusCircle className="plus-down-add me-0" />
                                      <h4>Add Images</h4>
                                    </div>
                                  </div>
                                </div>
                                {isImageVisible1 && (
                                  <div className="phone-img">
                                    <ImageWithBasePath
                                      src="assets/img/products/phone-add-2.png"
                                      alt="image"
                                    />
                                    <Link to="#">
                                      <X
                                        className="x-square-add remove-product"
                                        onClick={handleRemoveProduct1}
                                      />
                                    </Link>
                                  </div>
                                )}
                                {isImageVisible && (
                                  <div className="phone-img">
                                    <ImageWithBasePath
                                      src="assets/img/products/phone-add-1.png"
                                      alt="image"
                                    />
                                    <Link to="#">
                                      <X
                                        className="x-square-add remove-product"
                                        onClick={handleRemoveProduct}
                                      />
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row select-color-add">
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="input-blocks add-product">
                        <label>Variant Attribute</label>
                        <div className="row">
                          <div className="col-lg-10 col-sm-10 col-10">
                            <select
                              className="form-control variant-select select-option"
                              id="colorSelect"
                            >
                              <option>Choose</option>
                              <option>Color</option>
                              <option value="red">Red</option>
                              <option value="black">Black</option>
                            </select>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-2 ps-0">
                            <div className="add-icon tab">
                              <Link
                                className="btn btn-filter"
                                data-bs-toggle="modal"
                                data-bs-target="#add-units"
                              >
                                <PlusCircle className="feather feather-plus-circle" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="selected-hide-color" id="input-show">
                        <div className="row align-items-center">
                          <div className="col-sm-10">
                            <div className="input-blocks">
                              <input
                                className="input-tags form-control"
                                id="inputBox"
                                type="text"
                                data-role="tagsinput"
                                name="specialist"
                                defaultValue="red, black"
                              />
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="input-blocks ">
                              <Link to="#" className="remove-color">
                                <Trash2 />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal-body-table variant-table"
                    id="variant-table"
                  >
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Variantion</th>
                            <th>Variant Value</th>
                            <th>SKU</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th className="no-sort">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="add-product">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue="color"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="add-product">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue="red"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="add-product">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={1234}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="product-quantity">
                                <span className="quantity-btn">
                                  <i
                                    data-feather="minus-circle"
                                    className="feather-search"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="quntity-input"
                                  defaultValue={2}
                                />
                                <span className="quantity-btn">
                                  +
                                  <i
                                    data-feather="plus-circle"
                                    className="plus-circle"
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="add-product">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={50000}
                                />
                              </div>
                            </td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <div className="input-block add-lists">
                                  <label className="checkboxs">
                                    <input type="checkbox" defaultChecked="" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <Link
                                  className="me-2 p-2"
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add-variation"
                                >
                                  <i
                                    data-feather="plus"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link className="confirm-text p-2" to="#">
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="add-product">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue="color"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="add-product">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue="black"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="add-product">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={2345}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="product-quantity">
                                <span className="quantity-btn">
                                  <i
                                    data-feather="minus-circle"
                                    className="feather-search"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="quntity-input"
                                  defaultValue={3}
                                />
                                <span className="quantity-btn">
                                  +
                                  <i
                                    data-feather="plus-circle"
                                    className="plus-circle"
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="add-product">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={50000}
                                />
                              </div>
                            </td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                <div className="input-block add-lists">
                                  <label className="checkboxs">
                                    <input type="checkbox" defaultChecked="" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <Link
                                  className="me-2 p-2"
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-units"
                                >
                                  <i
                                    data-feather="plus"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link className="confirm-text p-2" to="#">
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="accordion-card-one accordion"
                id="accordionExample2"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list icon">
                          <h5>
                            <LifeBuoy className="add-info" />
                            <span>Pricing &amp; Stocks</span>
                          </h5>
                          <Link to="#">
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="input-blocks add-products">
                        <label className="d-block">
                          Product Type <span className="text-danger">*</span>
                        </label>
                        <div className="single-pill-product">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-4 mb-0 active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="payment"
                                />
                                <span className="checkmark" /> Single Product
                              </span>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-2 mb-0"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="sign"
                                />
                                <span className="checkmark" /> Variable Product
                              </span>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="row">
                            {selectedWarehouse?.map((warehouse, index) => (
                              <div
                                key={warehouse.value}
                                className="col-lg-4 col-sm-6 col-12"
                              >
                                <div className="input-blocks add-product">
                                  {/* Quantity Input */}
                                  <Controller
                                    name={`stock.${index}.quantity`}
                                    control={values.control}
                                    rules={{ required: "Quantity is required" }}
                                    render={({ field }) => (
                                      <InputField
                                        label={`${warehouse.label} Quantity`}
                                        type="text"
                                        {...field} // Spread field to handle value and onChange
                                        onChange={(e) => {
                                          field.onChange(e); // Update the Controller's value
                                          handleQuantityChange(index, e); // Call custom handler
                                        }}
                                      />
                                    )}
                                  />
                                  {values.errors?.stock?.[index]?.quantity && (
                                    <ErrorMessage
                                      error={
                                        values.errors.stock[index].quantity
                                          .message
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <Controller
                                  name="unit_id"
                                  control={values.control}
                                  rules={{ required: "Unit is required" }}
                                  render={({ field }) => (
                                    <Dropdown
                                      query={LIST_UNITS}
                                      variables={{
                                        storeId: getSelectedStoreDetails("id"),
                                      }}
                                      dataKey="listUnit"
                                      label="Unit"
                                      placeholder="Select a Unit"
                                      required={true}
                                      field={field}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  error={values.errors?.unit_id?.message}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <Controller
                                  name="price"
                                  control={values.control}
                                  rules={{ required: "Price is required" }}
                                  render={({ field }) => (
                                    <InputField
                                      label="Price"
                                      type="text"
                                      required={true}
                                      placeholder="Enter Price"
                                      value={values?.control}
                                      {...field}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  error={values.errors?.price?.message}
                                />
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Tax Type</label>
                                <Select
                                  classNamePrefix="react-select"
                                  options={taxtype}
                                  placeholder="Select Option"
                                />
                              </div>
                            </div> */}
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label className="form-label">
                                  Discount Type
                                </label>
                                <Controller
                                  name="off_type"
                                  control={values.control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      classNamePrefix="react-select"
                                      className="mb-2"
                                      options={values.discount_types}
                                      placeholder="Select Discount Type"
                                      value={values?.discount_types?.find(
                                        (option) => option.value === field.value
                                      )}
                                      onChange={(selectedOption) =>
                                        field.onChange(selectedOption.value)
                                      }
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  error={values.errors?.off_type?.message}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <Controller
                                  name="discount_value"
                                  control={values.control}
                                  render={({ field }) => (
                                    <InputField
                                      label="Discount Value"
                                      type="text"
                                      placeholder="Enter Discount Value"
                                      value={values?.control}
                                      {...field}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  error={values.errors?.discount_value?.message}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="accordion-card-one accordion"
                            id="accordionExample3"
                          >
                            <div className="accordion-item">
                              <div
                                className="accordion-header"
                                id="headingThree"
                              >
                                <div
                                  className="accordion-button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-controls="collapseThree"
                                >
                                  <div className="addproduct-icon list">
                                    <h5>
                                      <i
                                        data-feather="image"
                                        className="add-info"
                                      />
                                      <span>Images</span>
                                    </h5>
                                    <Link to="#">
                                      <ChevronDown className="chevron-down-add" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample3"
                              >
                                <div className="accordion-body">
                                  <div className="text-editor add-list add">
                                    <div className="col-lg-12">
                                      <Controller
                                        name="images"
                                        control={values?.control}
                                        rules={{
                                          required:
                                            "At least one image is required",
                                          validate: (files) =>
                                            files && files.length > 0
                                              ? true
                                              : "You must add an image",
                                        }}
                                        render={({ field }) => (
                                          <>
                                            <div className="add-choosen">
                                              <div className="input-blocks">
                                                <div className="image-upload">
                                                  <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                      const newFiles =
                                                        Array.from(
                                                          e.target.files
                                                        );
                                                      const updatedFiles = [
                                                        ...(field.value || []),
                                                        ...newFiles,
                                                      ];
                                                      field.onChange(
                                                        updatedFiles
                                                      );
                                                    }}
                                                    multiple
                                                  />
                                                  <div className="image-uploads">
                                                    <PlusCircle className="plus-down-add me-0" />
                                                    <h4>Add Images</h4>
                                                  </div>
                                                </div>
                                              </div>

                                              {field.value &&
                                                field.value.length > 0 && (
                                                  <div
                                                    className="image-preview"
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      gap: "10px",
                                                      justifyContent:
                                                        "flex-start",
                                                    }}
                                                  >
                                                    {field.value.map(
                                                      (file, index) => (
                                                        <div
                                                          key={index}
                                                          className="phone-img"
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            width: "130px",
                                                            height: "125px",
                                                            flexShrink: 0,
                                                          }}
                                                        >
                                                          <ImageWithBasePath
                                                            src={file}
                                                            alt={`Uploaded Image ${
                                                              index + 1
                                                            }`}
                                                          />
                                                          <Link to="#">
                                                            <X
                                                              className="x-square-add remove-product"
                                                              onClick={() => {
                                                                const updatedFiles =
                                                                  field.value.filter(
                                                                    (_, i) =>
                                                                      i !==
                                                                      index
                                                                  );
                                                                field.onChange(
                                                                  updatedFiles
                                                                );
                                                              }}
                                                            />
                                                          </Link>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                            </div>
                                            {values?.errors?.images && (
                                              <ErrorMessage
                                                error={
                                                  values.errors?.images?.message
                                                }
                                              />
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                    {/* <div className="col-lg-12"> */}
                                    {/* <div className="add-choosen">
                                        <div className="input-blocks">
                                          <div className="image-upload">
                                            <input type="file" />
                                            <div className="image-uploads">
                                              <PlusCircle className="plus-down-add me-0" />
                                              <h4>Add Images</h4>
                                            </div>
                                          </div>
                                        </div>
                                        {isImageVisible1 && (
                                          <div className="phone-img">
                                            <ImageWithBasePath
                                              src="assets/img/products/phone-add-2.png"
                                              alt="image"
                                            />
                                            <Link to="#">
                                              <X
                                                className="x-square-add remove-product"
                                                onClick={handleRemoveProduct1}
                                              />
                                            </Link>
                                          </div>
                                        )}
                                        {isImageVisible && (
                                          <div className="phone-img">
                                            <ImageWithBasePath
                                              src="assets/img/products/phone-add-1.png"
                                              alt="image"
                                            />
                                            <Link to="#">
                                              <X
                                                className="x-square-add remove-product"
                                                onClick={handleRemoveProduct}
                                              />
                                            </Link>
                                          </div>
                                        )}
                                      </div> */}
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div className="row select-color-add">
                            <div className="col-lg-6 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Variant Attribute</label>
                                <div className="row">
                                  <div className="col-lg-10 col-sm-10 col-10">
                                    <select
                                      className="form-control variant-select select-option"
                                      id="colorSelect"
                                    >
                                      <option>Choose</option>
                                      <option>Color</option>
                                      <option value="red">Red</option>
                                      <option value="black">Black</option>
                                    </select>
                                  </div>
                                  <div className="col-lg-2 col-sm-2 col-2 ps-0">
                                    <div className="add-icon tab">
                                      <Link
                                        className="btn btn-filter"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-units"
                                      >
                                        <PlusCircle className="feather feather-plus-circle" />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="selected-hide-color"
                                id="input-show"
                              >
                                <div className="row align-items-center">
                                  <div className="col-sm-10">
                                    <div className="input-blocks">
                                      <input
                                        className="input-tags form-control"
                                        id="inputBox"
                                        type="text"
                                        data-role="tagsinput"
                                        name="specialist"
                                        defaultValue="red, black"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="input-blocks ">
                                      <Link to="#" className="remove-color">
                                        <Trash2 />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="modal-body-table variant-table"
                            id="variant-table"
                          >
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Variantion</th>
                                    <th>Variant Value</th>
                                    <th>SKU</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th className="no-sort">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue="color"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue="red"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={1234}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="product-quantity">
                                        <span className="quantity-btn">
                                          <i
                                            data-feather="minus-circle"
                                            className="feather-search"
                                          />
                                        </span>
                                        <input
                                          type="text"
                                          className="quntity-input"
                                          defaultValue={2}
                                        />
                                        <span className="quantity-btn">
                                          +
                                          <i
                                            data-feather="plus-circle"
                                            className="plus-circle"
                                          />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={50000}
                                        />
                                      </div>
                                    </td>
                                    <td className="action-table-data">
                                      <div className="edit-delete-action">
                                        <div className="input-block add-lists">
                                          <label className="checkboxs">
                                            <input
                                              type="checkbox"
                                              defaultChecked=""
                                            />
                                            <span className="checkmarks" />
                                          </label>
                                        </div>
                                        <Link
                                          className="me-2 p-2"
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#add-variation"
                                        >
                                          <i
                                            data-feather="plus"
                                            className="feather-edit"
                                          />
                                        </Link>
                                        <Link
                                          className="confirm-text p-2"
                                          to="#"
                                        >
                                          <i
                                            data-feather="trash-2"
                                            className="feather-trash-2"
                                          />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue="color"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue="black"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={2345}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="product-quantity">
                                        <span className="quantity-btn">
                                          <i
                                            data-feather="minus-circle"
                                            className="feather-search"
                                          />
                                        </span>
                                        <input
                                          type="text"
                                          className="quntity-input"
                                          defaultValue={3}
                                        />
                                        <span className="quantity-btn">
                                          +
                                          <i
                                            data-feather="plus-circle"
                                            className="plus-circle"
                                          />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="add-product">
                                        <input
                                          type="text"
                                          className="form-control"
                                          defaultValue={50000}
                                        />
                                      </div>
                                    </td>
                                    <td className="action-table-data">
                                      <div className="edit-delete-action">
                                        <div className="input-block add-lists">
                                          <label className="checkboxs">
                                            <input
                                              type="checkbox"
                                              defaultChecked=""
                                            />
                                            <span className="checkmarks" />
                                          </label>
                                        </div>
                                        <Link
                                          className="me-2 p-2"
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#edit-units"
                                        >
                                          <i
                                            data-feather="plus"
                                            className="feather-edit"
                                          />
                                        </Link>
                                        <Link
                                          className="confirm-text p-2"
                                          to="#"
                                        >
                                          <i
                                            data-feather="trash-2"
                                            className="feather-trash-2"
                                          />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="accordion-card-one accordion"
                id="accordionExample4"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingFour">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-controls="collapseFour"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list">
                          <h5>
                            <List className="add-info" />
                            <span>Custom Fields</span>
                          </h5>
                          <Link to="#">
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample4"
                  >
                    <div className="accordion-body">
                      <div className="text-editor add-list add">
                        <div className="custom-filed">
                          <div className="input-block add-lists">
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Warranties
                            </label>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Manufacturer
                            </label>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Expiry
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks add-product">
                              <label>Discount Type</label>
                              <Select
                                classNamePrefix="react-select"
                                options={discounttype1}
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks add-product">
                              <label>Quantity Alert</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Manufactured Date</label>
                              <div className="input-groupicon calender-input">
                                <Calendar className="info-img" />
                                <DatePicker
                                  selected={selectedDate}
                                  onChange={handleDateChange}
                                  type="date"
                                  className="datetimepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Expiry On</label>
                              <div className="input-groupicon calender-input">
                                <Calendar className="info-img" />
                                <DatePicker
                                  selected={selectedDate1}
                                  onChange={handleDateChange1}
                                  type="date"
                                  className="datetimepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
              <button
                onClick={functions.onCancel}
                type="button"
                className="btn btn-cancel me-2"
              >
                Cancel
              </button>
              <Link onClick={functions.onSubmit} className="btn btn-submit">
                Save Product
              </Link>
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
      <Addunits />
      <AddCategory />
      <AddBrand />
    </div>
  );
};

export default AddProduct;
