import { useForm } from "react-hook-form";
import { RESET_PASSWORD } from "../apollo/mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../Router/all_routes";
import { payloadValidator, validateResponse } from "../utils/helperFunctions";

const useForgetPassword = () => {
  const route = all_routes;

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [submit] = useMutation(RESET_PASSWORD);

  const onResetPassword = async (data, email) => {
    try {
      let customPayload = {
        password: data.password,
        email: email,
      };

      let res = await submit(payloadValidator(customPayload));

      const { resestPassword } = res.data;

      if (validateResponse(resestPassword)) {
        navigate(route.signin);
      }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  return {
    values: {
      control,
      errors,
      watch,
    },
    functions: {
      onSubmit: (email) => handleSubmit((data) => onResetPassword(data, email)),
    },
  };
};

export default useForgetPassword;
