import { useQuery } from "@apollo/client";
import { GET_CATEGORY } from "../apollo/queries";
import { getSelectedStoreDetails } from "../utils/helperFunctions";

const useCategory = ({ pageStart, limit, parent_id }) => {
  const { loading, error, data } = useQuery(GET_CATEGORY, {
    variables: {
      input: {
        pageStart,
        limit,
        parent_id: parent_id ?? parent_id,
        store_id: getSelectedStoreDetails('id'),
        name: null,
        is_active: null,
      },
      getCategoryId: null,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("Error While Getting Brands", error);
  }

  return {
    values: {
      categoryList: data?.allCategory || [], // Assuming the API returns an items array
      total: data?.allCategory?.total || 0, // Assuming the API returns a total count
      loading,
    },
  };
};

export default useCategory;
