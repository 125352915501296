import { useQuery } from "@apollo/client";
import { GET_SUPPLIER } from "../apollo/queries";
import { SESSION_STORAGE } from "../common/masterEnums";
import { decryptData } from "../utils/encryptData";
import { ROLES } from "../utils/encryptedConstants.";
import { getSelectedStoreDetails } from "../utils/helperFunctions";

const useSuppplier = ({ pageStart, limit }) => {

  const roles = decryptData(ROLES, SESSION_STORAGE)

  const { loading, data } = useQuery(GET_SUPPLIER, {
    variables: {
      input: {
        pageStart: pageStart,
        limit: limit,
        role: roles?.map(item => item?.name),
        full_name: null,
        email: null,
        phone: null,
        store_id: getSelectedStoreDetails("id"),
      },
    },
    fetchPolicy: "network-only",
  });

  return {
    values: {
      userList: data?.allUserByRole || [], 
      total: data?.allUserByRole?.total || 0,
      loading,
    },
  };
};

export default useSuppplier;
