import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { USER_DETAILS } from "../utils/encryptedConstants.";

const PrivateRoute = () => {
  const isAuthenticated = !!sessionStorage.getItem(USER_DETAILS); // Or any specific session key you set

  return isAuthenticated ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoute;