import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { USER_DETAILS } from "../utils/encryptedConstants.";

const PublicRoute = () => {
  const isAuthenticated = !!sessionStorage.getItem(USER_DETAILS);

  return !isAuthenticated ? <Outlet /> : <Navigate to="/admin-dashboard" />;
};

export default PublicRoute;