import { ApolloProvider } from "@apollo/client";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "../src/style/css/feather.css";
import "../src/style/css/line-awesome.min.css";
import "../src/style/icons/fontawesome/css/all.min.css";
import "../src/style/icons/fontawesome/css/fontawesome.min.css";
import "../src/style/scss/main.scss";
import apollo from "./apollo/index.js";
import store from "./core/redux/store.jsx";
import AllRoutes from "./Router/router.jsx";
const rootElement = document.getElementById("root");

if (rootElement) {

  const root = createRoot(rootElement);

  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}

  root.render(
    <React.StrictMode>
      <ApolloProvider client={apollo}>
        <Provider store={store}>
          <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
            <AllRoutes />
            <ToastContainer />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    </React.StrictMode>
  );
} else {
  console.error("Element with id 'root' not found.");
}
