import { City, Country, State } from "country-state-city"

export const getCountries = () => {
    return Country.getAllCountries().map(item => ({ country_id: item.isoCode, label: `${item?.flag} ${item?.name}`, value: item.name }))
}

export const getStates = (country_id) => {
    let states = State.getStatesOfCountry(country_id)
    return states.map(item => ({ country_id: item.countryCode, state_id: item.isoCode, label: item.name, value: item.name }))
}

export const getCities = (country_id, state_id) => {
    let cities = City.getCitiesOfState(country_id, state_id)
    return cities.map(item => ({ label: item.name, value: item.name }))
}