import { useQuery } from "@apollo/client";
import { GET_UNITS } from "../apollo/queries";
import { getSelectedStoreDetails } from "../utils/helperFunctions";

const useUnits = ({ pageStart, limit }) => {
  const { loading, error, data } = useQuery(GET_UNITS, {
    variables: {
      input: {
        pageStart,
        limit,
        store_id: getSelectedStoreDetails("id"),
        name: null,
      },
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("Error While Getting Brands", error);
  }

  return {
    values: {
      allUnit: data?.allUnit || [], // Assuming the API returns an items array
      total: data?.allUnit?.total || 0, // Assuming the API returns a total count
      loading,
    },
  };
};

export default useUnits;
