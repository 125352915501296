import React from "react";
import { Link } from "react-router-dom";
import useCreateUnits from "../../../controllers/useCreateUnits";
import { Controller } from "react-hook-form";
import InputField from "../../../feature-module/components/InputField";
import ErrorMessage from "../../../feature-module/components/errorMessage";

const AddUnit = () => {
  const { values, functions } = useCreateUnits();
  return (
    <div>
      {/* Add Unit */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Unit</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={functions.onCancel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-3">
                      <Controller
                        name="name"
                        control={values.control}
                        rules={{ required: "Unit name is required" }}
                        render={({ field }) => (
                          <InputField
                            label="Unit Name"
                            type="text"
                            required
                            placeholder="Enter Unit (for e.g boxes, gram, kilogram)"
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage error={values.errors.name?.message} />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={functions.onCancel}
                      >
                        Cancel
                      </button>
                      <Link
                        onClick={functions.onSubmit}
                        className="btn btn-submit"
                      >
                        Create Unit
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Unit */}
    </div>
  );
};

export default AddUnit;
