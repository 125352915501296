import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import useFetchData from "../../../controllers/useFetchData";

const Dropdown = ({
  query,
  variables,
  label,
  placeholder,
  dataKey,
  onChange,
  field,
  disabled,
  isMulti = false,
  required = false
}) => {
  const { data, error } = useFetchData(query, variables);
  if (error) return <div>Error loading {label}</div>;

  let options = [];

  if (dataKey === "listCategories" && Array.isArray(data?.[dataKey])) {
    options = data[dataKey]
      .filter((item) => item.parent_id === null)
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
  } else if (Array.isArray(data?.[dataKey])) {
    options = data[dataKey].map((item) => ({
      value: item.id,
      label: item.name,
    }));
  } else if (data?.[dataKey]) {
    options = [data[dataKey]].map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
  }

  return (
    <div>
      <label className="form-label">{label}{required && <span className="text-danger"> *</span>}</label>
      <Select
        {...field}
        options={options}
        isDisabled={disabled}
        classNamePrefix="react-select"
        placeholder={placeholder}
        isMulti={isMulti}
        value={
          isMulti
            ? options.filter((option) => field.value?.includes(option.value))
            : options.find((option) => option.value === field.value)
        }
        onChange={(selectedOption) => {
          if (isMulti) {
            const selectedValues =
              selectedOption?.map((opt) => opt.value) || [];
            field.onChange(selectedValues);
            console.log("selectedValues", selectedOption);
            onChange && onChange(selectedOption);
          } else if (dataKey == "listCategories") {
            onChange && onChange(data, selectedOption);
          } else {
            field.onChange(selectedOption?.value);
            onChange && onChange(selectedOption);
          }
        }}
      />
    </div>
  );
};

Dropdown.propTypes = {
  query: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  variables: PropTypes.object,
  label: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
};

export default Dropdown;
