import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ADD_CUSTOMER } from "../apollo/mutations";
import closeModal from "../common/closeModal";
import { getCities, getCountries, getStates } from "../utils/country";
import { getSelectedStoreDetails, payloadValidator } from "../utils/helperFunctions";
import { decryptData } from "../utils/encryptData";
import { GENERAL } from "../utils/encryptedConstants.";
import { SESSION_STORAGE } from "../common/masterEnums";

const defaultValues = {
  full_name: "",
  email: "",
  phone: "",
  business: {
    name: "",
    type: "",
    ein_number: "",
    address: {
      address: "",
      country: "",
      city: "",
      state: "",
      zip: "",
    },
  },
};

const useCreateCustomer = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues });

  const general = decryptData(GENERAL, SESSION_STORAGE);

  const [submit] = useMutation(ADD_CUSTOMER);

  const onAddCustomer = async (data) => {
    try {
      const { business, full_name, email, phone } = data || {};

      const businessFields = {
        name: business?.name ?? null,
        type: business?.type ?? null,
        address: {
          city: business?.address?.city.value ?? "",
          state: business?.address?.state.value ?? "",
          country: business?.address?.country.value ?? "",
          zip: business?.address?.zip ?? "",
        },
        license: business?.license ?? null,
        ein_number: business?.ein_number ?? null,
      };

      const payload = {
        full_name: full_name ?? "",
        email: email === "" ? null : email,
        phone: phone ?? "",
        store_id: getSelectedStoreDetails("id"),
        business: [businessFields],
      };
      await submit(payloadValidator(payload));
      reset(defaultValues);
      closeModal("add-units");
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const country = watch("business.address.country");
  const state = watch("business.address.state");

  const resetField = (field) => setValue(field, "");

  return {
    values: {
      control,
      errors,
      image: watch("image"),
      countries: getCountries(),
      states: country ? getStates(country?.country_id) : [],
      cities:
        country && state ? getCities(state.country_id, state.state_id) : [],
      business_types: general?.business_type?.map((item) => ({
        label: item,
        value: item,
      })),
    },
    functions: {
      onSubmit: handleSubmit(onAddCustomer),
      onCancel,
      resetField,
    },
  };
};

export default useCreateCustomer;
