import { useQuery } from "@apollo/client";
import { GET_CUSTOMERS } from "../apollo/queries";

const useCustomer = ({ pageStart, limit }) => {
  console.log("pageStart", pageStart);
  const { loading, error, data } = useQuery(GET_CUSTOMERS, {
    variables: {
      input: {
        limit,
        pageStart,
        email: null,
        full_name: null,
      },
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("Error While Getting Brands", error);
  }

  return {
    values: {
      allCustomer: data?.allCustomer || [],
      total: data?.allCustomer?.total || 0,
      loading,
    },
  };
};

export default useCustomer;
