import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  getSelectedStoreDetails,
  payloadValidator,
} from "../utils/helperFunctions";
import { UPDATE_PRODUCTS } from "../apollo/mutations";
import { LIST_CATEGORY, PRODUCT_DETAIL } from "../apollo/queries";
import { useEffect } from "react";
// import { label } from "yet-another-react-lightbox";
const defaultValues = {
  id: null,
  sku: null,
  name: null,
  description: null,
  category_id: null,
  subCategory_id: null,
  brand_id: null,
  images: null,
  newimages: null,
  price: null,
  off_type: null,
  discount_value: null,
  stock: [],
};

const useEditProduct = (productId) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues });

  const { loading, error, data, refetch } = useQuery(PRODUCT_DETAIL, {
    skip: !productId,
    variables: { productDetailId: productId },
    onCompleted: (data) => {
      if (data?.productDetail) {
        const productDetails = data.productDetail;
        const mappedValues = {
          id: productDetails.id,
          sku: productDetails.sku,
          name: productDetails.name,
          description: productDetails.description,
          category_id:
            productDetails.category?.parent_id == null
              ? productDetails.category?.id
              : productDetails.category?.parent_id,
          subCategory_id:
            productDetails.category?.parent_id != null &&
            productDetails.category?.id,
          brand_id: productDetails.brand?.id || null,
          images: productDetails.images || null,
          price: productDetails.price || null,
          off_type: productDetails.off_type || null,
          discount_value: productDetails.discount_value || null,
          unit_id: productDetails.unit.id || null,
          warehouse_id: productDetails.stock.map((item) => item.warehouse.id),
          stock: productDetails.stock.map((item) => ({
            id: item.id || 0,
            quantity: item.quantity || 0,
            warehouse_id: item.warehouse.id || 0,
          })),
        };

        reset(mappedValues);
      }
    },
  });

  const { data: categoryData, refetch: refetchCategory } = useQuery(
    LIST_CATEGORY,
    {
      // Only trigger when parent_id exists
      variables: { storeId: getSelectedStoreDetails("id") },
    }
  );

  useEffect(() => {
    if (productId) {
      refetch();
      refetchCategory();
    }
  }, [productId, refetch]);

  const [submit] = useMutation(UPDATE_PRODUCTS);

  const onEditProduct = async (data) => {
    try {
      console.log("data", data);
      const handleImages = (images) => {
        const newImages = [];
        const filteredImages = images.filter((image) => {
          if (typeof image !== "string") {
            newImages.push(image);
            return false;
          }
          return true;
        });
        return { filteredImages, newImages };
      };
      const { filteredImages, newImages } = handleImages(data.images || []);
      console.log("data", data?.subCategory_id)
      let category_id =
        data?.subCategory_id == "false"
          ? data?.category_id
          : data?.subCategory_id;
      delete data.warehouse_id;
      delete data?.subCategory_id;
      let payload = {
        ...data,
        images: filteredImages,
        newimages: newImages,
        sku: data?.sku?.toString(),
        price: parseFloat(data?.price),
        discount_value: parseFloat(data?.discount_value),
        category_id: category_id,
        stock: data?.stock.map((item) => ({
          ...item,
          quantity: parseInt(item?.quantity),
        })),
      };
      delete data.subCategory_id;
      console.log("payload", payload);
      await submit(payloadValidator(payload));
      reset(defaultValues);
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return {
    values: {
      control,
      errors,
      image: watch("image"),
      loading,
      error,
      data,
      categoryData,
    },
    setValue,
    getValues,
    functions: {
      onSubmit: handleSubmit(onEditProduct),
      onCancel,
    },
  };
};

export default useEditProduct;
