import React from "react";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";
import useCreateCategory from "../../../controllers/useCreateCategory";
import ErrorMessage from "../../../feature-module/components/errorMessage";
import InputField from "../../../feature-module/components/InputField";

const AddCategoryList = () => {

  const { values, functions } = useCreateCategory({ id: null });
  
  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={functions.onCancel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-3">
                      <Controller
                        name="name"
                        control={values.control}
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <InputField
                            label="Category"
                            type="text"
                            value={values?.control}
                            placeholder="Enter Category Name"
                            required
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage error={values.errors.name?.message} />
                    </div>
                    {/* <div className="mb-3">
                      <Controller
                        name="description"
                        control={values.control}
                        render={({ field }) => (
                          <InputField
                            label="Description"
                            type="textarea"
                            value={values?.control}
                            placeholder="Enter Description"
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        error={values.errors.description?.message}
                      />
                    </div> */}
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <Controller
                          name="is_active"
                          control={values.control}
                          render={({ field }) => {
                            console.log("ADD FIELDS", field);
                            return (
                              <>
                                <input
                                  type="checkbox"
                                  id="add-categoryStatus"
                                  className="check"
                                  checked={field.value}
                                  onChange={(e) => {
                                    console.log("e", e);
                                    field.onChange(e.target.checked);
                                  }} // Update form state on change
                                />
                                <label
                                  htmlFor="add-categoryStatus"
                                  className="checktoggle"
                                />
                              </>
                            );
                          }}
                        />
                        {/* <input
                          type="checkbox"
                          id="user2"
                          className="check"
                          defaultChecked="true"
                        />
                        <label htmlFor="user2" className="checktoggle" /> */}
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={functions.onCancel}
                      >
                        Cancel
                      </button>
                      <Link
                        to="#"
                        className="btn btn-submit"
                        onClick={functions.onSubmit}
                      >
                        Create Category
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddCategoryList;
