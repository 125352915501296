import PropTypes from "prop-types";
import React, { useState } from "react";

const InputField = (props) => {

  const { label, type, value, isSecure, onChange, required = false, helper_text } = props

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="form-login mb-2">
      {label && <label className="form-label">{label}{required && <span className="text-danger"> *</span>}</label>}
      {
        type == "textarea" ?
          <>
            <textarea
              className="form-control h-100"
              rows={5}
              value={value}
              onChange={(e) => {
                if (onChange) onChange(e.target.value);
              }}
              {...props}
            />
          </> :
          <div className="pass-group">
            <input
              type={isPasswordVisible ? "password" : type}
              className="form-control"
              value={value}
              onChange={(e) => {
                if (onChange) onChange(e.target.value);
              }}
              {...props}
            />
            {isSecure && (
              <span
                className={`fas toggle-password ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"
                  }`}
                onClick={togglePasswordVisibility}
              ></span>
            )}
          </div>
      }
      {
        helper_text &&
        <p className="mt-1">{helper_text}</p>
      }
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  helper_text: PropTypes.string,
  isSecure: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  rest: PropTypes.object.isRequired,
};

export default InputField;
