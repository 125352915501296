/* eslint-disable */

import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../apollo/mutations";
import { GENERAL_LOV, ROLES_LOV, STORE_LOV } from "../apollo/queries";
import { SESSION_STORAGE } from "../common/masterEnums";
import { all_routes } from "../Router/all_routes";
import { encryptData } from "../utils/encryptData";
import { ACCESS_TOKEN, GENERAL, REFRESH_TOKEN, ROLES, SELECTED_STORE, STORE_LIST, USER_DETAILS } from "../utils/encryptedConstants.";
import { payloadValidator, validateResponse } from "../utils/helperFunctions";

const defaultValues = {
  email: "",
  password: "",
};

const useSignin = () => {

  const route = all_routes;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(LOGIN);

  const [getRolesLov] = useLazyQuery(ROLES_LOV)
  const [getStoresLov] = useLazyQuery(STORE_LOV)
  const [getGeneralLov] = useLazyQuery(GENERAL_LOV)

  const navigate = useNavigate();

  const onLogin = async (data) => {
    try {

      let res = await submit(payloadValidator(data));

      const { login } = res.data;

      if (validateResponse(login)) {

        encryptData(login.data, USER_DETAILS, SESSION_STORAGE);
        encryptData(login.accessToken, ACCESS_TOKEN, SESSION_STORAGE);
        encryptData(login.refreshToken, REFRESH_TOKEN, SESSION_STORAGE);

        let roles = await getRolesLov()
        encryptData(roles?.data?.listRole, ROLES, SESSION_STORAGE);

        let general = await getGeneralLov()
        encryptData(general?.data?.listEnum, GENERAL, SESSION_STORAGE);

        if (login?.data?.role?.some(item => item?.name !== "Admin")) {
          let stores = await getStoresLov()
          if (stores && stores?.data && stores?.data?.listStore?.length > 0) {
            encryptData(stores?.data?.listStore, STORE_LIST, SESSION_STORAGE);
            encryptData(stores?.data?.listStore[0], SELECTED_STORE, SESSION_STORAGE);
          }
        }

        navigate(route.dashboard)

      }

    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  return {
    values: {
      control,
      errors,
    },
    functions: {
      onSubmit: handleSubmit(onLogin),
    },
  };
};

export default useSignin;
