import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { EDIT_UNIT } from "../apollo/mutations";
import { useEffect } from "react";
import closeModal from "../common/closeModal";
import { payloadValidator } from "../utils/helperFunctions";

const useEditUnit = (rowData) => {
  const defaultValues = {
    id: rowData?.id ?? 0,
    name: rowData?.name ?? "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(EDIT_UNIT);

  useEffect(() => {
    reset(defaultValues);
  }, [rowData, reset]);

  const onEditUnit = async (data) => {
    try {
      let res = await submit(payloadValidator(data));
      console.log("Res From Create Brand", res);
      reset(defaultValues);
      closeModal("edit-units");
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    closeModal("edit-units");
  };

  return {
    values: {
      control,
      errors,
    },
    functions: {
      onSubmit: handleSubmit(onEditUnit),
      onCancel,
    },
  };
};

export default useEditUnit;
