import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { EDIT_BRANDS } from "../apollo/mutations";
import { useEffect } from "react";
import closeModal from "../common/closeModal";

const useEditBrand = (rowData) => {

  const defaultValues = {
    id: rowData?.id ?? 0,
    name: rowData?.name ?? "",
    is_active: rowData?.is_active ?? true,
    image: null
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(EDIT_BRANDS);

  useEffect(() => {
    reset(defaultValues);
  }, [rowData, reset]);

  const onEditBrand = async (data) => {
    try {
      let payload = {
        variables: {
          input: {
            id: data.id,
            name: data.name,
            is_active: data.is_active,
          },
          image: data.image,
        },
      };

      await submit(payload);
      reset(defaultValues);
      closeModal("edit-brand");

      // const modalElement = document.getElementById("edit-brand");
      // if (modalElement) {
      //   modalElement.classList.remove("show"); // Removes the visible modal class
      //   modalElement.style.display = "none"; // Hides the modal element
      //   document.body.classList.remove("modal-open"); // Removes the body overlay
      //   const backdrop = document.querySelector(".modal-backdrop");
      //   if (backdrop) backdrop.remove(); // Removes the backdrop
      // }

      // document.querySelector(".btn-cancel").click();
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    closeModal("edit-brand");
  };

  return {
    values: {
      control,
      errors,
      image: watch("image"),
    },
    functions: {
      onSubmit: handleSubmit(onEditBrand),
      onCancel,
    },
  };
};

export default useEditBrand;
