import React from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import useCreateBrand from "../../../controllers/useCreateBrand";
import ErrorMessage from "../../../feature-module/components/errorMessage";
import InputField from "../../../feature-module/components/InputField";

const AddBrand = () => {
  const { values, functions } = useCreateBrand();

  return (
    <>
      {/* Add Brand */}
      <div className="modal fade" id="add-brand">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Brand</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={functions.onCancel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body new-employee-field">
                  <form>
                    <div className="mb-3">
                      <Controller
                        name="name"
                        control={values.control}
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <InputField
                            label="Brand"
                            type="text"
                            required
                            placeholder="Enter Brand Name"
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage error={values.errors.name?.message} />
                      {/* <label className="form-label">Brand</label>
                      <input type="text" className="form-control" /> */}
                    </div>
                    <label className="form-label">Logo <span className="text-danger">*</span></label>
                    <Controller
                      name="image"
                      control={values.control}
                      rules={{
                        required: "Logo is required",
                        validate: {
                          checkFileType: (value) => {
                            return (
                              value?.type === "image/jpeg" ||
                              value?.type === "image/png" ||
                              "Only JPEG and PNG formats are allowed"
                            );
                          },
                          checkFileSize: (value) => {
                            return (
                              value?.size < 5000000 ||
                              "Image must be smaller than 5MB"
                            );
                          },
                        },
                      }}
                      render={({ field }) => (
                        <div className="profile-pic-upload mb-3">
                          <div className="profile-pic brand-pic">
                            {field.value ? (
                              <img
                                src={URL.createObjectURL(field.value)}
                                alt="Profile Preview"
                                className="image-preview"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <span>Select Image</span>
                            )}
                          </div>
                          <div className="image-upload mb-0">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                field.onChange(e.target.files[0])
                              }
                              ref={field.ref}
                            />
                            <div className="image-uploads">
                              <h4>{field.value ? "Change" : "Add"} Image</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    {values.errors.image && (
                      <ErrorMessage error={values.errors.image.message} />
                    )}
                    {/* <div className="profile-pic-upload mb-3">
                                            <div className="profile-pic brand-pic">
                                                <span>
                                                    <PlusCircle className="plus-down-add"/>
                                                    Add Image
                                                </span>
                                            </div>
                                            <div className="image-upload mb-0">
                                                <input type="file" />
                                                <div className="image-uploads">
                                                    <h4>Change Image</h4>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <Controller
                          name="is_active"
                          control={values.control}
                          render={({ field }) => (
                            <>
                              <input
                                type="checkbox"
                                id="add-brandstatus"
                                className="check"
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                } // Update form state on change
                              />
                              <label
                                htmlFor="add-brandstatus"
                                className="checktoggle"
                              />
                            </>
                          )}
                        />
                        {/* <input
                          type="checkbox"
                          id="user2"
                          className="check"
                          defaultChecked="true"
                        />
                        <label htmlFor="user2" className="checktoggle" /> */}
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={functions.onCancel}
                      >
                        Cancel
                      </button>
                      <Link
                        onClick={functions.onSubmit}
                        to="#"
                        className="btn btn-submit"
                      >
                        Create Brand
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Brand */}
    </>
  );
};

export default AddBrand;
