import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ADD_BRANDS } from "../apollo/mutations";
import closeModal from "../common/closeModal";
import { getSelectedStoreDetails } from "../utils/helperFunctions";

const defaultValues = {
  name: "",
  is_active: true,
  image: "",
};

const useCreateBrand = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(ADD_BRANDS);

  const onAddBrands = async (data) => {
    try {
      let payload = {
        variables: {
          input: {
            name: data.name,
            is_active: data.is_active,
            store_id: getSelectedStoreDetails("id"),
          },
          image: data.image,
        },
      };

      console.log("payload", payload)

      let res = await submit(payload);
      reset(defaultValues);

      console.log("Res From Create Brand", res);

      closeModal("add-brand");
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
      // const modalElement = document.getElementById("add-brand");
      // if (modalElement) {
      //   modalElement.classList.remove("show"); // Removes the visible modal class
      //   modalElement.style.display = "none"; // Hides the modal element
      //   document.body.classList.remove("modal-open"); // Removes the body overlay
      //   const backdrop = document.querySelector(".modal-backdrop");
      //   if (backdrop) backdrop.remove(); // Removes the backdrop
      // }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return {
    values: {
      control,
      errors,
      image: watch("image"),
    },
    functions: {
      onSubmit: handleSubmit(onAddBrands),
      onCancel,
    },
  };
};

export default useCreateBrand;
