import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../apollo/queries";
import { getSelectedStoreDetails } from "../utils/helperFunctions";

const useProducts = ({ pageStart, limit }) => {
  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    variables: {
      input: {
        pageStart,
        limit,
        name: null,
        store_id: getSelectedStoreDetails('id'),
      },
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("Error While Getting Brands", error);
  }

  return {
    values: {
      allProduct: data?.allProduct || [],
      total: data?.allProduct?.total || 0,
      loading,
    },
  };
};

export default useProducts;
