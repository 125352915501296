import { useForm } from "react-hook-form";
import { REQUEST_PASSWORD } from "../apollo/mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../Router/all_routes";
import { payloadValidator, validateResponse } from "../utils/helperFunctions";

const defaultValues = {
  email: "",
};

const useForgetPassword = () => {
  const route = all_routes;

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(REQUEST_PASSWORD);

  const onForgetPassword = async (data) => {
    try {
      let res = await submit(payloadValidator(data));

      const { requestPassowrd } = res.data;

      if (validateResponse(requestPassowrd)) {
        navigate(route.twostepverification, {
          state: { email: requestPassowrd.data.email },
        });
      }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  return {
    values: {
      control,
      errors,
    },
    functions: {
      onSubmit: handleSubmit(onForgetPassword),
    },
  };
};

export default useForgetPassword;
