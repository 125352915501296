import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ADD_SUPPLIER } from "../apollo/mutations";
import closeModal from "../common/closeModal";
import { SESSION_STORAGE } from "../common/masterEnums";
import { decryptData } from "../utils/encryptData";
import { ROLES } from "../utils/encryptedConstants.";
import { getSelectedStoreDetails } from "../utils/helperFunctions";

const defaultValues = {
  full_name: "",
  email: "",
  password: "",
  phone: "",
  role: "",
  is_active: true,
  image: null,
};

const useCreateSupplier = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(ADD_SUPPLIER);

  let roles = decryptData(ROLES, SESSION_STORAGE);

  const onAddSupplier = async (data) => {
    try {
      let payload = {
        variables: {
          input: {
            store_id: getSelectedStoreDetails('id'),
            full_name: data?.full_name,
            email: data?.email,
            password: data?.password,
            phone: data?.phone,
            role: data?.role?.map(item => item?.value),
            is_active: data?.is_active,
          },
          image: data?.image,
        },
      };

      await submit(payload);
      reset(defaultValues);
      closeModal("add-units");
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return {
    values: {
      control,
      errors,
      image: watch("image"),
      roles: roles.map((item) => ({ label: item.name, value: item.name })),
    },
    functions: {
      onSubmit: handleSubmit(onAddSupplier),
      onCancel,
    },
  };
};

export default useCreateSupplier;
