import { gql } from "@apollo/client";

// LIST APIS

export const ROLES_LOV = gql`
  query Query {
    listRole {
      id
      name
      createdAt
    }
  }
`;

export const STORE_LOV = gql`
  query ListStore {
    listStore {
      id
      name
      is_active
    }
  }
`;

export const GENERAL_LOV = gql`
  query Query {
    listEnum
  }
`;

// END LIST APIS

export const GET_BRANDS = gql`
  query Query($input: AllBrandInput!) {
    allBrand(input: $input) {
      total
      nextPage
      data {
        id
        name
        image
        is_active
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query AllCategory($input: AllCategoryInput!) {
    allCategory(input: $input) {
      total
      nextPage
      data {
        id
        name
        description
        icon
        parent_id
        path
        is_active
        store {
          id
          name
          owner {
            id
            full_name
            email
            image
            phone
            fcm_token
            is_active
            is_verified
            createdAt
            updatedAt
          }
          is_active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_SUPPLIER = gql`
  query Query($input: UserByRoleInput!) {
    allUserByRole(input: $input) {
      total
      nextPage
      data {
        id
        full_name
        email
        image
        phone
        is_active
        is_verified
        createdAt
        updatedAt
        role {
          id
          name
          createdAt
        }
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query AllCustomer($input: AllCustomerInput) {
    allCustomer(input: $input) {
      total
      nextPage
      data {
        id
        full_name
        email
        phone
        business {
          id
          name
          type
          address
          license
          ein_number
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query AllProduct($input: AllProductInput) {
    allProduct(input: $input) {
      total
      nextPage
      data {
        id
        sku
        name
        description
        category {
          id
          name
          description
          icon
          parent_id
          path
          is_active
          createdAt
          updatedAt
        }
        brand {
          id
          name
          image
          is_active
          createdAt
        }
        unit {
          id
          name
        }
        images
        price
        off_type
        discount_value
        is_available
        stock {
          id
          warehouse {
            id
            name
            phone
            store_id
            address
            createdAt
            updatedAt
          }
          quantity
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const LIST_WAREHOUSE = gql`
  query ListWarehouse($storeId: Int!) {
    listWarehouse(store_id: $storeId) {
      id
      name
      phone
      store_id
      address
      createdAt
      updatedAt
    }
  }
`;

export const LIST_BRANDS = gql`
  query Query($storeId: Int!) {
    listBrand(store_id: $storeId) {
      id
      name
      image
      is_active
      createdAt
      updatedAt
    }
  }
`;

export const LIST_CATEGORY = gql`
  query Query($storeId: Int!) {
    listCategories(store_id: $storeId) {
      id
      name
      description
      icon
      parent_id
      path
      is_active
      store {
        id
        name
        owner {
          id
          full_name
          email
          image
          phone
          fcm_token
          is_active
          is_verified
          createdAt
          updatedAt
        }
        is_active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const PRODUCT_DETAIL = gql`
  query Query($productDetailId: Int!) {
    productDetail(id: $productDetailId) {
      id
      sku
      name
      description
      category {
        id
        name
        description
        icon
        parent_id
        path
        is_active
        store {
          id
          name
          owner {
            id
            full_name
            email
            image
            phone
            fcm_token
            is_active
            is_verified
            createdAt
            updatedAt
          }
          is_active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      brand {
        id
        name
        image
        is_active
        createdAt
        updatedAt
      }
      unit {
        id
        name
      }
      images
      price
      off_type
      discount_value
      is_available
      stock {
        id
        warehouse {
          id
          name
          phone
          store_id
          address
          createdAt
          updatedAt
        }
        quantity
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_STORE = gql`
  query Query($input: AllStoreInput!) {
    allStore(input: $input) {
      total
      nextPage
      data {
        id
        name
        owner {
          id
          full_name
          email
          image
          phone
          fcm_token
          is_active
          is_verified
          createdAt
          updatedAt
        }
        is_active
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_UNITS = gql`
  query Query($input: AllUnitInput!) {
    allUnit(input: $input) {
      total
      nextPage
      data {
        id
        name
      }
    }
  }
`;

export const LIST_UNITS = gql`
  query Query($storeId: Int!) {
    listUnit(store_id: $storeId) {
      id
      name
    }
  }
`;

export const GET_CATEGORY_BY_ID = gql`
  query GetCategory($getCategoryId: Int!) {
    getCategory(id: $getCategoryId) {
      id
      name
      description
      icon
      parent_id
      path
      is_active
      store {
        id
        name
        owner {
          id
          full_name
          email
          image
          phone
          fcm_token
          is_active
          is_verified
          createdAt
          updatedAt
        }
        is_active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
