import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import useCreateSupplier from "../../../controllers/useCreateSupplier";
import ErrorMessage from "../../../feature-module/components/errorMessage";
import InputField from "../../../feature-module/components/InputField";

const SupplierModal = () => {
  const { values, functions } = useCreateSupplier();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div>
      {/* Add Supplier */}
      <div className="modal fade" id="add-units">
        <div
          className="modal-dialog modal-dialog-centered custom-modal-two"
          style={{ maxWidth: "70%" }}
        >
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add User</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={functions.onCancel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="new-employee-field">
                          <span>Avatar</span>
                          <Controller
                            name="image"
                            control={values.control}
                            rules={{
                              // required: "Image is required",
                              validate: {
                                checkFileType: (value) => {
                                  if (value) {
                                    return (
                                      value?.type === "image/jpeg" ||
                                      value?.type === "image/png" ||
                                      "Only JPEG and PNG formats are allowed"
                                    );
                                  }
                                },
                                checkFileSize: (value) => {
                                  if (value) {
                                    return (
                                      value?.size < 5000000 ||
                                      "Image must be smaller than 5MB"
                                    );
                                  }
                                },
                              },
                            }}
                            render={({ field }) => (
                              <div className="profile-pic-upload mb-2">
                                <div className="profile-pic">
                                  {field.value ? (
                                    <img
                                      src={URL.createObjectURL(field.value)}
                                      alt="Profile Preview"
                                      className="image-preview"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    <span>Select Image</span>
                                  )}
                                </div>
                                <div className="image-upload mb-0">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      field.onChange(e.target.files[0])
                                    }
                                    ref={field.ref}
                                  />
                                  <div className="image-uploads">
                                    <h4>
                                      {field.value ? "Change" : "Add"} Image
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                        {values.errors.image && (
                          <ErrorMessage error={values.errors.image.message} />
                        )}
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <Controller
                            name="full_name"
                            control={values.control}
                            rules={{ required: "Full Name is required" }}
                            render={({ field }) => (
                              <InputField
                                label="Full Name"
                                type="text"
                                value={values?.control}
                                required
                                placeholder="Enter Full Name"
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage
                            error={values.errors.full_name?.message}
                          />
                          {/* <label>Supplier Name</label>
                          <input type="text" className="form-control" /> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <Controller
                            name="email"
                            control={values.control}
                            rules={{ required: "Email is required" }}
                            render={({ field }) => (
                              <InputField
                                label="Email"
                                type="email"
                                required
                                placeholder="Enter Email Address"
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage error={values.errors.email?.message} />
                          {/* <label>Email</label>
                          <input type="email" className="form-control" /> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <Controller
                            name="phone"
                            control={values.control}
                            rules={{ required: "Phone is required" }}
                            render={({ field }) => (
                              <InputField
                                label="Phone"
                                type="number"
                                required
                                placeholder="Enter Phone Number"
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage error={values.errors.phone?.message} />
                          {/* <label>Phone</label>
                          <input type="text" className="form-control" /> */}
                        </div>
                      </div>
                      {/* <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <Controller
                            name="role"
                            control={values.control}
                            rules={{ required: "Role is required" }}
                            render={({ field }) => (
                              <>
                                <label>Roles</label>
                                <Select
                                  {...field}
                                  isMulti
                                  classNamePrefix="react-select"
                                  options={values.roles}
                                  placeholder="Select a role"
                                  value={field.value}
                                  onChange={(selectedOption) =>
                                    field.onChange(selectedOption)
                                  }
                                />
                              </>
                            )}
                          />
                          <ErrorMessage error={values.errors.role?.message} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <Controller
                          name="password"
                          control={values.control}
                          rules={{ required: "Password is required" }}
                          render={({ field }) => (
                            <>
                              <label className="form-label">
                                Password<span className="text-danger"> *</span>
                              </label>
                              <div className="pass-group">
                                <input
                                  placeholder="Enter Password"
                                  type={isPasswordVisible ? "text" : "password"}
                                  className="pass-input form-control"
                                  {...field}
                                />
                                <span
                                  className={`fas toggle-password ${isPasswordVisible
                                    ? "fa-eye"
                                    : "fa-eye-slash"
                                    }`}
                                  onClick={togglePasswordVisibility}
                                ></span>
                              </div>
                            </>
                          )}
                        />
                        <ErrorMessage error={values.errors.password?.message} />
                      </div>
                      <div className="col-lg-6 d-flex justify-content-center align-items-center">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center w-100 mt-3">
                          <span className="status-label">Status</span>
                          <Controller
                            name="is_active"
                            control={values.control}
                            render={({ field }) => (
                              <>
                                <input
                                  type="checkbox"
                                  id="add-supplier"
                                  className="check"
                                  checked={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.checked)
                                  }
                                />
                                <label
                                  htmlFor="add-supplier"
                                  className="checktoggle"
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            className="form-control mb-1"
                            defaultValue={""}
                          />
                          <p>Maximum 600 Characters</p>
                        </div>
                      </div> */}
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={functions.onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={functions.onSubmit}
                        type="submit"
                        className="btn btn-submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Supplier */}
    </div>
  );
};

export default SupplierModal;
