import { useForm } from "react-hook-form";
import { VERIFY_OTP } from "../apollo/mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../Router/all_routes";
import { payloadValidator, validateResponse } from "../utils/helperFunctions";

const useVerifyOtp = () => {
  const route = all_routes;

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submit] = useMutation(VERIFY_OTP);

  const onVerifyPassword = async (data, email) => {
    try {
      let customPayload = {
        email: email,
        otp: parseInt(Object.values(data).slice(0, 6).join("")),
      };

      let res = await submit(payloadValidator(customPayload));

      const { requestVerify } = res.data;

      if (validateResponse(requestVerify)) {
        navigate(route.resetpassword, {
          state: { email: email },
        });
      }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  return {
    values: {
      control,
      errors,
    },
    functions: {
      onSubmit: (email) =>
        handleSubmit((data) => onVerifyPassword(data, email)),
    },
  };
};

export default useVerifyOtp;
