import {
  ROLES,
  SELECTED_STORE,
  STORE_LIST,
  USER_DETAILS,
} from "./encryptedConstants.";

export const logout = () => {
  sessionStorage.removeItem(USER_DETAILS);
  sessionStorage.removeItem(SELECTED_STORE);
  sessionStorage.removeItem(STORE_LIST);
  sessionStorage.removeItem(ROLES);
  sessionStorage.clear();
};
