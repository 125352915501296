import { useQuery } from "@apollo/client";

const useFetchData = (query, variables = {}, fetchPolicy = "network-only") => {
  const { loading, error, data } = useQuery(query, {
    variables,
    // variables: {
    //   input: variables,
    // },
    fetchPolicy,
  });

  if (error) {
    console.error("Error fetching data:", error);
  }

  return {
    loading,
    data,
    error,
  };
};

export default useFetchData;
