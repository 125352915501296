import React from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import useUpdateProfile from "../../controllers/useUpdateProfile";
import ErrorMessage from "../../feature-module/components/errorMessage";
import InputField from "../../feature-module/components/InputField";
import { Controller } from "react-hook-form";
import { decryptData } from "../../utils/encryptData";
import { USER_DETAILS } from "../../utils/encryptedConstants.";
import { SESSION_STORAGE } from "../../common/masterEnums";

const Profile = () => {
  const userDetails = decryptData(USER_DETAILS, SESSION_STORAGE);
  const { values, functions } = useUpdateProfile();
  // const [isPasswordVisible, setPasswordVisible] = useState(false);
  console.log("values, functions", values, functions);

  // const togglePasswordVisibility = () => {
  //   setPasswordVisible((prevState) => !prevState);
  // };

  console.log("userDetails", userDetails);
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Profile</h4>
            <h6>User Profile</h6>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head"></div>
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentimg">
                    <ImageWithBasePath
                      src="assets/img/customer/customer5.jpg"
                      alt="img"
                      id="blah"
                    />
                    <div className="profileupload">
                      <input type="file" id="imgInp" />
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/icons/edit-set.svg"
                          alt="img"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="profile-contentname">
                    <h2>{userDetails?.full_name}</h2>
                    <h4>Updates Your Photo and Personal Details.</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <Controller
                    name="full_name"
                    control={values.control}
                    rules={{ required: "Name is required" }}
                    render={({ field }) => (
                      <InputField
                        label="Name"
                        type="text"
                        placeholder="Enter Name"
                        value={values?.control}
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage error={values.errors.full_name?.message} />
                  {/* <label className="form-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="William"
                  /> */}
                </div>
              </div>
              {/* <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Castilo"
                  />
                </div>
              </div> */}
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <Controller
                    name="email"
                    control={values.control}
                    rules={{ required: "Email is required" }}
                    render={({ field }) => (
                      <InputField
                        label="Email"
                        placeholder="Enter Email"
                        type="text"
                        disabled
                        value={values?.control}
                        {...field}
                      />
                    )}
                  />
                  {/* <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    defaultValue="william@example.com"
                  /> */}
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <Controller
                    name="phone"
                    control={values.control}
                    rules={{ required: "Phone is required" }}
                    render={({ field }) => (
                      <InputField
                        label="Phone"
                        type="number"
                        placeholder="Enter Phone"
                        value={values?.control}
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage error={values.errors.phone?.message} />
                  {/* <label className="form-label">Phone</label>
                  <input type="text" defaultValue="+1452 876 5432" /> */}
                </div>
              </div>
              {/* <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="William Castilo"
                  />
                </div>
              </div> */}
              {/* <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <Controller
                    name="current_password"
                    control={values.control}
                    rules={{ required: "Current Password is required" }}
                    render={({ field }) => (
                      <InputField
                        label="Current Password"
                        isSecure={true}
                        type="text"
                        value={values?.control}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div> */}

              <div className="col-12">
                <Link
                  onClick={functions.onSubmit}
                  to="#"
                  className="btn btn-submit me-2"
                >
                  Submit
                </Link>
                <Link to="#" className="btn btn-cancel">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default Profile;
