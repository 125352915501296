import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Mutation($input: LoginInput!) {
    login(input: $input) {
      message
      statusCode
      data {
        id
        full_name
        email
        image
        phone
        role {
          id
          name
        }
        fcm_token
        is_active
        is_verified
        createdAt
        updatedAt
      }
      accessToken
      refreshToken
    }
  }
`;

export const REQUEST_PASSWORD = gql`
  mutation RequestPassowrd($input: RequestPasswordInput!) {
    requestPassowrd(input: $input) {
      message
      statusCode
      data
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation RequestVerify($input: RequestVerifyInput!) {
    requestVerify(input: $input) {
      message
      statusCode
      data
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResestPassword($input: ResetPasswordInput!) {
    resestPassword(input: $input) {
      message
      statusCode
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation Mutation($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      message
      statusCode
    }
  }
`;

export const GET_CUSTOMER = gql`
  mutation RequestPassowrd($input: RequestPasswordInput!) {
    requestPassowrd(input: $input) {
      message
      statusCode
      data
    }
  }
`;

export const ADD_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomersInput!) {
    createCustomer(input: $input) {
      message
      statusCode
    }
  }
`;

export const ADD_BRANDS = gql`
  mutation Mutation($input: CreateBrandInput!, $image: Upload!) {
    createBrand(input: $input, image: $image) {
      message
      statusCode
    }
  }
`;

export const EDIT_BRANDS = gql`
  mutation Mutation($input: UpdateBrandInput!, $image: Upload) {
    updateBrand(input: $input, image: $image) {
      message
      statusCode
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation CreateCategory($input: CreateCategoryInput!, $icon: Upload) {
    createCategory(input: $input, icon: $icon) {
      message
      statusCode
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation Mutation($input: UpdateCategoryInput!, $icon: Upload) {
    updateCategory(input: $input, icon: $icon) {
      message
      statusCode
    }
  }
`;

export const ADD_SUPPLIER = gql`
  mutation CreateUser($input: CreateUserInput!, $image: Upload) {
    createUser(input: $input, image: $image) {
      message
      statusCode
    }
  }
`;

export const EDIT_SUPPLIER = gql`
  mutation Mutation($input: UpdateCategoryInput!, $icon: Upload) {
    updateCategory(input: $input, icon: $icon) {
      message
      statusCode
    }
  }
`;

export const CREATE_PRODUCTS = gql`
  mutation Mutation($input: CreateProductInput!) {
    createProduct(input: $input) {
      message
      statusCode
    }
  }
`;

export const UPDATE_PRODUCTS = gql`
  mutation Mutation($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      message
      statusCode
    }
  }
`;

export const CREATE_STORE = gql`
  mutation Mutation($input: CreateStoreInput!, $image: Upload!) {
    createStore(input: $input, image: $image) {
      message
      statusCode
    }
  }
`;

export const CREATE_UNIT = gql`
  mutation Mutation($input: CreateUnitInput!) {
    createUnit(input: $input) {
      message
      statusCode
    }
  }
`;

export const EDIT_UNIT = gql`
  mutation Mutation($input: UpdateUnitInput!) {
    updateUnit(input: $input) {
      message
      statusCode
    }
  }
`;

// export const EDIT_PRODUCTS = gql`
//   mutation Mutation($input: UpdateProductInput!) {
//     updateProduct(input: $input) {
//       message
//       statusCode
//     }
//   }
// `;
