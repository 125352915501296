import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { SESSION_STORAGE } from "../common/masterEnums";
import { showErrorToast } from "../utils";
import { decryptData } from "../utils/encryptData";
import { ACCESS_TOKEN } from "../utils/encryptedConstants.";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors?.length > 0) {
    showErrorToast(graphQLErrors?.[0]?.message);
  } else if (networkError) {
    showErrorToast("A network error occurred");
  }

});

const authLink = setContext((_, { headers }) => {
  const token = decryptData(ACCESS_TOKEN, SESSION_STORAGE);
  console.log("Token", token)
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = new HttpLink({
  uri: BASE_URL,
});

const uploadLink = createUploadLink({
  uri: BASE_URL,
});

const apollo = new ApolloClient({
  link: from([errorLink, authLink, uploadLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default apollo;
