import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ADD_CATEGORY } from "../apollo/mutations";
import closeModal from "../common/closeModal";
import {
  getSelectedStoreDetails,
} from "../utils/helperFunctions";

const defaultValues = {
  name: "",
  description: null,
  is_active: true,
  parent_id: null,
};

const useCreateCategory = ({ id }) => {

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(ADD_CATEGORY);

  const onAddCategory = async (data) => {
    try {
      let payload = {
        variables: {
          input: {
            ...(id ? { parent_id: id } : {}),
            name: data.name,
            is_active: data.is_active,
            description: data?.description,
            store_id: getSelectedStoreDetails("id"),
          },
          icon: null,
        },
      };

      await submit(payload);
      reset(defaultValues);
      closeModal("add-category");

    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    closeModal("add-category");
  };

  return {
    values: {
      control,
      errors,
    },
    functions: {
      onSubmit: handleSubmit(onAddCategory),
      onCancel,
    },
  };
};

export default useCreateCategory;
