// import { X } from "feather-icons-react/build/IconComponents";
import React from "react";
import { Link } from "react-router-dom";
// import ImageWithBasePath from "../../img/imagewithbasebath";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import useEditBrand from "../../../controllers/useEditBrand";
import ErrorMessage from "../../../feature-module/components/errorMessage";
import InputField from "../../../feature-module/components/InputField";
import ImageWithBasePath from "../../img/imagewithbasebath";

const EditBrand = ({ rowData }) => {

  const { values, functions } = useEditBrand(rowData);

  return (
    <div>
      {/* Edit Brand */}
      <div className="modal fade" id="edit-brand">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Brand</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={functions.onCancel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body new-employee-field">
                  <form>
                    <div className="mb-3">
                      <Controller
                        name="name"
                        control={values.control}
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <InputField
                            label="Brand"
                            type="text"
                            required
                            placeholder="Enter Brand"
                            value={field?.value}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage error={values.errors.name?.message} />
                      {/* <label className="form-label">Brand</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="Boat"
                      /> */}
                    </div>
                    <label className="form-label">Logo <span className="text-danger">*</span></label>
                    <div className=" mb-3">
                      <Controller
                        name="image"
                        control={values.control}
                        rules={{
                          validate: {
                            checkFileType: (value) => {
                              if (value) {
                                return (
                                  value?.type === "image/jpeg" ||
                                  value?.type === "image/png" ||
                                  "Only JPEG and PNG formats are allowed"
                                );
                              }
                            },
                            checkFileSize: (value) => {
                              if (value) {
                                return (
                                  value?.size < 5000000 ||
                                  "Image must be smaller than 5MB"
                                );
                              }
                            },
                          },
                        }}
                        render={({ field }) => (
                          <div className="profile-pic-upload mb-2">
                            <div className="profile-pic brand-pic">
                              {field.value ? (
                                <ImageWithBasePath
                                  src={field.value}
                                  alt="Profile Preview"
                                  className="image-preview"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                  isApiImage={false}
                                />
                              ) : rowData?.image ? (
                                <ImageWithBasePath
                                  src={rowData?.image}
                                  alt="Profile Preview"
                                  className="image-preview"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                  isApiImage={true}
                                />
                              ) : (
                                <span>Select Image</span>
                              )}
                            </div>
                            <div className="image-upload mb-0">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  field.onChange(e.target.files[0])
                                }
                                ref={field.ref}
                              />
                              <div className="image-uploads">
                                <h4>{field.value ? "Change" : "Add"} Image</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                      {values.errors.image && (
                        <ErrorMessage error={values.errors.image.message} />
                      )}
                    </div>
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <Controller
                          name="is_active"
                          control={values.control}
                          render={({ field }) => (
                            <>
                              <input
                                type="checkbox"
                                id="edit-brandstatus"
                                className="check"
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                } // Update form state on change
                              />
                              <label
                                htmlFor="edit-brandstatus"
                                className="checktoggle"
                              />
                            </>
                          )}
                        />
                        {/* <input
                          type="checkbox"
                          id="user4"
                          className="check"
                          defaultChecked="true"
                        />
                        <label htmlFor="user4" className="checktoggle" /> */}
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={functions.onCancel}
                      >
                        Cancel
                      </button>
                      <Link
                        onClick={functions.onSubmit}
                        to="#"
                        className="btn btn-submit"
                      >
                        Save Changes
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Brand */}
    </div>
  );
};

EditBrand.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    is_active: PropTypes.bool.isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditBrand;
