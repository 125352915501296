import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { EDIT_CATEGORY } from "../apollo/mutations";
import closeModal from "../common/closeModal";

const useEditCategory = (rowData) => {

  const defaultValues = {
    id: rowData?.id ?? 0,
    name: rowData?.name ?? "",
    description: null,
    is_active: rowData?.is_active ?? true,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(EDIT_CATEGORY);

  useEffect(() => {
    reset(defaultValues);
  }, [rowData, reset]);

  const onEditCategory = async (data) => {
    try {
      let payload = {
        variables: {
          input: {
            id: data.id,
            name: data.name,
            description: data.description,
            is_active: data.is_active,
          },
          icon: null,
        },
      };

      await submit(payload);
      reset(defaultValues);
      closeModal("edit-category");

    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
    closeModal("edit-category");
  };

  return {
    values: {
      control,
      errors,
    },
    functions: {
      onSubmit: handleSubmit(onEditCategory),
      onCancel,
    },
  };
};

export default useEditCategory;
