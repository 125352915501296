import React from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import InputField from "../../../feature-module/components/InputField";
import ErrorMessage from "../../../feature-module/components/errorMessage";
import PropTypes from "prop-types";
import useEditUnit from "../../../controllers/useEditUnit";

const EditUnit = ({ rowData }) => {
  const { values, functions } = useEditUnit(rowData);
  console.log("rowData", rowData);
  return (
    <div>
      {/* Edit Unit */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Unit</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={functions.onCancel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-3">
                      <Controller
                        name="name"
                        control={values.control}
                        rules={{ required: "Unit name is required" }}
                        render={({ field }) => (
                          <InputField
                            label="Unit Name"
                            type="text"
                            placeholder="Enter Unit (for e.g boxes, gram, kilogram)"
                            required
                            value={field?.value}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage error={values.errors.name?.message} />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={functions.onCancel}
                      >
                        Cancel
                      </button>
                      <Link
                        onClick={functions.onSubmit}
                        className="btn btn-submit"
                      >
                        Save Changes
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditUnit.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditUnit;
