const closeModal = (id) => {
  const modalElement = document.getElementById(id);
  if (modalElement) {
    modalElement.classList.remove("show");
    modalElement.style.display = "none";
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) backdrop.remove();
  }
};
export default closeModal;
