/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { Table } from "antd";

const Datatable = ({
  props,
  columns,
  dataSource,
  total,
  onPageChange,
  loading,
}) => {
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const currentPageRef = useRef(1);

  const handleTableChange = (pagination) => {
    console.log("pagination", pagination)
    if (currentPageRef.current !== pagination.current) {
      onPageChange(pagination.current - 1, pagination.pageSize);
      currentPageRef.current = pagination.current;
    }
  };

  return (
    <Table
      key={props}
      className="table datanew dataTable no-footer"
      // rowSelection={rowSelection}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.id}
      pagination={{
        current: currentPageRef.current,
        total,
        pageSize: 10,
        // showSizeChanger: false,
      }}
      onChange={handleTableChange}
      loading={loading}
    />
  );
};

export default Datatable;
