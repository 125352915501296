import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { UPDATE_PROFILE } from "../apollo/mutations";
import { decryptData, encryptData } from "../utils/encryptData";
import { USER_DETAILS } from "../utils/encryptedConstants.";
import { SESSION_STORAGE, SUCCESS } from "../common/masterEnums";
import { useEffect } from "react";

const useUpdateProfile = () => {
  let userDetails = decryptData(USER_DETAILS, SESSION_STORAGE);
  const defaultValues = {
    full_name: userDetails?.full_name ?? "",
    phone: parseInt(userDetails?.phone) ?? "",
    email: userDetails?.email ?? "",
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(UPDATE_PROFILE);

  const onUpdateProfile = async (data) => {
    try {
      let payload = {
        variables: {
          input: {
            full_name: data?.full_name,
            password: {
              current_password: null,
              new_password: null,
            },
            phone: data?.phone.toString(),
          },
        },
      };

      let res = await submit(payload);

      if (res?.data?.updateProfile?.statusCode == SUCCESS) {
        let updatedUserProfile = {
          ...userDetails,
          full_name: data?.full_name,
          phone: data?.phone.toString(),
        };
        encryptData(updatedUserProfile, USER_DETAILS, SESSION_STORAGE);
      }
    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  return {
    values: {
      control,
      errors,
      image: watch("image"),
    },
    functions: {
      onSubmit: handleSubmit(onUpdateProfile),
    },
  };
};

export default useUpdateProfile;
