import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CREATE_UNIT } from "../apollo/mutations";
import closeModal from "../common/closeModal";
import {
  getSelectedStoreDetails,
  payloadValidator,
} from "../utils/helperFunctions";

const defaultValues = {
  name: "",
  store_id: null,
};

const useCreateUnits = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [submit] = useMutation(CREATE_UNIT);

  const onAddUnit = async (data) => {

    try {
      let payload = {
        ...data,
        store_id: getSelectedStoreDetails("id")
      }
      await submit(payloadValidator(payload));
      reset(defaultValues);
      closeModal("add-units");

    } catch (error) {
      console.log("Mutation error:", error.message);
    }
  };

  const onCancel = () => {
    reset(defaultValues);
  };

  return {
    values: {
      control,
      errors,
    },
    functions: {
      onSubmit: handleSubmit(onAddUnit),
      onCancel,
    },
  };
};

export default useCreateUnits;
