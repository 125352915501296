import React from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import useVerifyOtp from "../../../controllers/useVerifyOtp";
import { Controller } from "react-hook-form";

const Twostepverification = () => {
  const route = all_routes;

  const location = useLocation();

  const { email } = location.state || {};
  
  const { values, functions } = useVerifyOtp();

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-content">
            <div className="login-userset">
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                </div>
              </div>
              <Link to={route.dashboard} className="login-logo logo-white">
                <ImageWithBasePath src="assets/img/logo-white.png" alt />
              </Link>
              <div className="login-userheading">
                <h3>Login With Your Email Address</h3>
                <h4 className="verfy-mail-content">
                  We sent a verification code to your email. Enter the code from
                  the email in the field below
                </h4>
              </div>
              <form action="index" className="digit-group">
                <div className="wallet-add">
                  <div className="otp-box">
                    <div className="forms-block text-center">
                      {[1, 2, 3, 4, 5, 6].map((digit, index) => (
                        <Controller
                          key={index}
                          name={`digit${digit}`}
                          control={values.control}
                          rules={{
                            required: `Digit ${digit} is required`,
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Must be a number",
                            },
                          }}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              maxLength={1}
                              className="otp-input"
                              id={`digit-${digit}`}
                            />
                          )}
                        />
                      ))}
                      {Object.keys(values.errors).length > 0 && (
                        <p className="error-message">
                          Please enter valid digits in each box.
                        </p>
                      )}
                      {/* <input
                        type="text"
                        id="digit-1"
                        maxLength={1}
                        pattern="[0-9]"
                        defaultValue=""
                      />
                      <input
                        type="text"
                        id="digit-2"
                        maxLength={1}
                        pattern="[0-9]"
                        defaultValue=""
                      />
                      <input
                        type="text"
                        id="digit-3"
                        maxLength={1}
                        pattern="[0-9]"
                        defaultValue=""
                      />
                      <input
                        type="text"
                        id="digit-4"
                        maxLength={1}
                        pattern="[0-9]"
                        defaultValue=""
                      />
                       <input
                        type="text"
                        id="digit-5"
                        maxLength={1}
                        pattern="[0-9]"
                        defaultValue=""
                      />
                       <input
                        type="text"
                        id="digit-6"
                        maxLength={1}
                        pattern="[0-9]"
                        defaultValue=""
                      /> */}
                    </div>
                  </div>
                </div>
                {/* <div className="Otp-expire text-center">
                  <p>Otp will expire in 09 :10</p>
                </div> */}
                <div className="form-login mt-4">
                  <Link onClick={functions.onSubmit(email)} className="btn btn-login">
                    Verify My Account
                  </Link>
                </div>
              </form>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>
                  Copyright © {new Date().getFullYear()} Samsal Services. All
                  rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Twostepverification;
