import React from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Link } from "react-router-dom";
import InputField from "../../../feature-module/components/InputField";
import { Controller } from "react-hook-form";
import ErrorMessage from "../../../feature-module/components/errorMessage";
import useCreateCustomer from "../../../controllers/useCreateCustomer";

const CustomerModal = () => {
  const { values, functions } = useCreateCustomer();

  const varrelOptions = [{ value: "varrel", label: "Varrel" }];

  const germanyOptions = [
    { value: "germany", label: "Germany" },
    { value: "unitedStates", label: "United States" },
  ];

  return (
    <>
      {/* Add Customer */}
      <div className="modal fade" id="add-units">
        <div
          className="modal-dialog modal-dialog-centered custom-modal-two"
          style={{ maxWidth: "70%" }}
        >
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Customer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <Controller
                            name="full_name"
                            control={values.control}
                            rules={{ required: "Name is required" }}
                            render={({ field }) => (
                              <InputField
                                label="Customer Name"
                                type="text"
                                placeholder="Enter Customer Name"
                                required
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage
                            error={values.errors.full_name?.message}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <Controller
                            name="email"
                            control={values.control}
                            render={({ field }) => (
                              <InputField
                                label="Email"
                                type="email"
                                placeholder="Enter Customer Email"
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage error={values.errors.email?.message} />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <Controller
                            name="phone"
                            control={values.control}
                            rules={{ required: "Phone is required" }}
                            render={({ field }) => (
                              <InputField
                                label="Phone"
                                type="email"
                                placeholder="Enter Customer Phone"
                                required
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage
                            error={values.errors.phone?.message}
                          />
                        </div>
                      </div>
                      <div className="page-title mb-4 mt-1">
                        <h4>Business Details</h4>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <Controller
                            name="business.name"
                            control={values.control}
                            rules={{ required: "Business Name is required" }}
                            render={({ field }) => (
                              <InputField
                                label="Business Name"
                                type="text"
                                placeholder="Enter Business Name"
                                required
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage
                            error={values.errors.business?.name?.message}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <label className="form-label">Business Type <span className="text-danger">*</span></label>
                          <Controller
                            name="business.type"
                            control={values.control}
                            rules={{ required: "Business Type is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                classNamePrefix="react-select"
                                className="mb-2"
                                options={values?.business_types}
                                placeholder="Select Business Type"
                                value={values?.business_types?.find(
                                  (option) => option.value === field.value
                                )}
                                onChange={(selectedOption) =>
                                  field.onChange(selectedOption.value)
                                }
                              />
                            )}
                          />
                          <ErrorMessage
                            error={values.errors.business?.type?.message}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="input-blocks">
                          <div className="mb-3">
                            <Controller
                              name="business.ein_number"
                              control={values.control}
                              rules={{ required: "Tax Id is required" }}
                              render={({ field }) => (
                                <InputField
                                  label="Tax Id #"
                                  placeholder="Enter Tax Id"
                                  type="text"
                                  required
                                  {...field}
                                />
                              )}
                            />
                            <ErrorMessage
                              error={
                                values.errors.business?.ein_number?.message
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 pe-0">
                        <div className="mb-3">
                          <Controller
                            name="business.address.address"
                            control={values.control}
                            rules={{ required: "Address is required" }}
                            render={({ field }) => (
                              <InputField
                                label="Address"
                                placeholder="Enter Address"
                                type="text"
                                required
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage
                            error={
                              values.errors.business?.address?.address?.message
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 pe-0">
                        <div className="mb-3">
                          <label className="form-label">Country</label>
                          <Controller
                            name="business.address.country"
                            control={values.control}
                            rules={{ required: "Country is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                classNamePrefix="react-select"
                                className="mb-2"
                                options={values.countries}
                                placeholder="Select Country"
                                value={values.countries.find(
                                  (option) => option.value === field?.value?.value
                                )}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption)
                                  functions.resetField("business.address.state")
                                  functions.resetField("business.address.city")
                                }
                                }
                              />
                            )}
                          />
                          <ErrorMessage
                            error={
                              values.errors.business?.address?.country?.message
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 pe-0">
                        <div className="mb-3">
                          <label className="form-label">State</label>
                          <Controller
                            name="business.address.state"
                            control={values.control}
                            rules={{ required: "State is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                classNamePrefix="react-select"
                                className="mb-2"
                                options={values.states}
                                placeholder="Select State"
                                value={values.states.find(
                                  (option) => option.value === field?.value?.value
                                )}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption)
                                  functions.resetField("business.address.city")
                                }
                                }
                              />
                            )}
                          />
                          <ErrorMessage
                            error={
                              values.errors.business?.address?.state?.message
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 pe-0">
                        <div className="mb-3">
                          <label className="form-label">City</label>
                          <Controller
                            name="business.address.city"
                            control={values.control}
                            rules={{ required: "City is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                classNamePrefix="react-select"
                                className="mb-2"
                                options={values.cities}
                                placeholder="Select City"
                                value={values.cities.find(
                                  (option) => option.value === field?.value?.value
                                )}
                                onChange={(selectedOption) =>
                                  field.onChange(selectedOption)
                                }
                              />
                            )}
                          />
                          <ErrorMessage
                            error={
                              values.errors.business?.address?.city?.message
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 pe-0">
                        <div className="mb-3">
                          <Controller
                            name="business.address.zip"
                            control={values.control}
                            rules={{ required: "Zip is required" }}
                            render={({ field }) => (
                              <InputField
                                label="Zip"
                                type="text"
                                placeholder="Enter Zip"
                                required
                                {...field}
                              />
                            )}
                          />
                          <ErrorMessage
                            error={
                              values.errors.business?.address?.zip?.message
                            }
                          />
                        </div>
                      </div>
                      <div className="modal-title-head people-cust-avatar">
                        <h6>License</h6>
                      </div>
                      <div className="new-employee-field">
                        <div className="profile-pic-upload">
                          {/* <div className="profile-pic">
                            <span>
                              <i
                                data-feather="plus-circle"
                                className="plus-down-add"
                              />{" "}
                              License Image
                            </span>
                          </div> */}
                          <div className="mb-3">
                            <Controller
                              name="business.license"
                              control={values.control}
                              // rules={{
                              //   required: "Image is required",
                              //   validate: {
                              //     checkFileType: (value) => {
                              //       return (
                              //         value?.type === "image/jpeg" ||
                              //         value?.type === "image/png" ||
                              //         "Only JPEG and PNG formats are allowed"
                              //       );
                              //     },
                              //     checkFileSize: (value) => {
                              //       return (
                              //         value?.size < 5000000 ||
                              //         "Image must be smaller than 5MB"
                              //       );
                              //     },
                              //   },
                              // }}
                              render={({ field }) => (
                                <div className="profile-pic-upload mb-2">
                                  <div className="profile-pic">
                                    {field.value ? (
                                      <img
                                        src={URL.createObjectURL(field.value)}
                                        alt="Profile Preview"
                                        className="image-preview"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    ) : (
                                      <span>License Image</span>
                                    )}
                                  </div>
                                  <div className="image-upload mb-0">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        field.onChange(e.target.files[0])
                                      }
                                      ref={field.ref}
                                    />
                                    <div className="image-uploads">
                                      <h4>
                                        {field.value ? "Change" : "Add"} Image
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            />
                            {/* <div className="image-upload mb-0">
                              <Controller
                                name="business.license"
                                control={values.control}
                                rules={{ required: "License is required" }}
                                render={({ field }) => (
                                  <input type="file" {...field} />
                                )}
                              />
                              <div className="image-uploads">
                                <h4>Change Image</h4>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={functions.onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={functions.onSubmit}
                        type="submit"
                        className="btn btn-submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Customer */}

      {/* Edit Customer */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Customer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="modal-title-head people-cust-avatar">
                      <h6>Avatar</h6>
                    </div>
                    <div className="new-employee-field">
                      <div className="profile-pic-upload">
                        <div className="profile-pic people-profile-pic">
                          <ImageWithBasePath
                            src="assets/img/profiles/profile.png"
                            alt="Img"
                          />
                          <Link to="#">
                            <i
                              data-feather="x-square"
                              className="x-square-add"
                            />
                          </Link>
                        </div>
                        <div className="mb-3">
                          <div className="image-upload mb-0">
                            <input type="file" />
                            <div className="image-uploads">
                              <h4>Change Image</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <label className="form-label">Customer Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Thomas"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            defaultValue="thomas@example.com"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 pe-0">
                        <div className="input-blocks">
                          <label className="mb-2">Phone</label>
                          <input
                            className="form-control form-control-lg group_formcontrol"
                            id="phone2"
                            name="phone2"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 pe-0">
                        <div className="mb-3">
                          <label className="form-label">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Budapester Strasse 2027259 "
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pe-0">
                        <div className="mb-3">
                          <label className="form-label">City</label>
                          <Select
                            classNamePrefix="react-select"
                            options={varrelOptions}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pe-0">
                        <div className="mb-3">
                          <label className="form-label">Country</label>
                          <Select
                            classNamePrefix="react-select"
                            options={germanyOptions}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            className="form-control mb-1"
                            defaultValue={""}
                          />
                          <p>Maximum 60 Characters</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Customer */}
    </>
  );
};

export default CustomerModal;
