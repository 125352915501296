/* eslint-disable */

import React from "react";

const ImageWithBasePath = (props) => {
  const { isApiImage } = props;
  const altText = String(props.alt);
  const fullSrc =
    typeof props.src === "object"
      ? URL.createObjectURL(props?.src)
      : `${isApiImage == true ? process.env.REACT_APP_IMAGE_URL : ""}${props.src}`;
  return (
    <img
      className={props.className}
      src={fullSrc}
      height={props.height}
      alt={altText}
      width={props.width}
      id={props.id}
    />
  );
};

export default ImageWithBasePath;
