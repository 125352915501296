import { SESSION_STORAGE } from "../common/masterEnums";
import { decryptData } from "./encryptData";
import { SELECTED_STORE } from "./encryptedConstants.";

export const validateResponse = (response) => {
  if (response.statusCode === 200) {
    return true;
  } else {
    return false;
  }
};

export const payloadValidator = (data) => {
  if (data) {
    let payload = {
      variables: {
        input: data,
      },
    };
    return payload;
  }
  return null;
};

export const getSelectedStoreDetails = (key = null) => {
  const storedData = decryptData(SELECTED_STORE, SESSION_STORAGE);
  if (!storedData) {
    console.log("No selected store found in session storage.");
    return null;
  }
  try {
    const selectedStore = storedData;
    if (key) {
      return selectedStore[key] || `Key "${key}" not found in selected store.`;
    }
    return selectedStore;
  } catch (error) {
    console.log("error selected store from session storage:", error);
    return null;
  }
};
