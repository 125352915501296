import { toast } from "react-toastify";

export const showErrorToast = (message) => {
    toast.error(message || 'An error occurred', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};