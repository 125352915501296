import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { Controller } from "react-hook-form";
import useResetPassword from "../../../controllers/useResetPassword";

const Resetpassword = () => {
  const route = all_routes;

  const location = useLocation();

  const { email } = location.state || {};

  const { values, functions } = useResetPassword();

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const password = values.watch("password", "");

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper reset-pass-wrap bg-img">
          <div className="login-content">
            <form action="success-3">
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                </div>
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt />
                </Link>
                <div className="login-userheading">
                  <h3>Reset password?</h3>
                  <h4>
                    Enter New Password &amp; Confirm Password to get inside
                  </h4>
                </div>
                {/* <div className="form-login">
                  <label> Old Password</label>
                  <div className="pass-group">
                    <input type="password" className="pass-input" />
                    <span className="fas toggle-password fa-eye-slash" />
                  </div>
                </div> */}
                <div className="form-login">
                  <label>New Password</label>
                  <div className="pass-group">
                    <Controller
                      name="password"
                      control={values.control}
                      rules={{ required: "Password is required" }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type={isPasswordVisible ? "text" : "password"}
                          className="pass-input"
                          placeholder="Enter new password"
                        />
                      )}
                    />
                    {values.errors.password && (
                      <p className="error">{values.errors.password.message}</p>
                    )}
                    <span
                      className={`fas toggle-password ${
                        isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                </div>
                <div className="form-login">
                  <label>Confirm Password</label>
                  <div className="pass-group">
                    <Controller
                      name="confirmPassword"
                      control={values.control}
                      rules={{
                        required: "Please confirm your password",
                        validate: (value) =>
                          value === password || "Passwords do not match",
                      }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type={isPasswordVisible ? "text" : "password"}
                          className="pass-input"
                          placeholder="Confirm new password"
                        />
                      )}
                    />
                    {values.errors.confirmPassword && (
                      <p className="error">{values.errors.confirmPassword.message}</p>
                    )}
                    <span
                      className={`fas toggle-password ${
                        isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                </div>
                {/* <div className="form-login">
                  <label> New Confirm Passworrd</label>
                  <div className="pass-group">
                    <input type="password" className="pass-inputa" />
                    <span className="fas toggle-passworda fa-eye-slash" />
                  </div>
                </div> */}
                {/* <div className="form-login">
                  <label> New Confirm Passworrd</label>
                  <div className="pass-group">
                    <input type="password" className="pass-inputa" />
                    <span className="fas toggle-passworda fa-eye-slash" />
                  </div>
                </div> */}
                <div className="form-login">
                  <Link onClick={functions.onSubmit(email)} className="btn btn-login">
                    Change Password
                  </Link>
                </div>
                <div className="signinform text-center">
                  <h4>
                    Return to{" "}
                    <Link to={route.signin} className="hover-a">
                      {" "}
                      login{" "}
                    </Link>
                  </h4>
                </div>
                <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                  <p>
                    Copyright © {new Date().getFullYear()} Samsal Services. All
                    rights reserved
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
